import React, { useEffect, ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../../store/store';
import { IPatient } from '../../store/app/app.types';
import { updateProfile } from '../../store/app/app.thunks';
import { setIsMenuVisible } from '../../store/ui/ui.slice';
import Welcome from '../common/contentWrappers/Welcome';
import Disclaimer from '../common/contentWrappers/Disclaimer';
import InvitedToClinicModal from '../invitedToClinicModal/InvitedToClinicModal';

function Onboarding({ children }: Readonly<{ children?: ReactNode }>): JSX.Element {
    const patient: IPatient = useSelector((state: RootState) => state.app.patient);

    const dispatch = useDispatch();
    const history = useHistory();

    const showWelcomeDisclaimer = !patient.isUnenrolled && patient.isEnrolled;
    const showWelcome = !patient.welcomeSeen && showWelcomeDisclaimer;
    const showDisclaimer = !patient.disclaimerSeen && patient.welcomeSeen && showWelcomeDisclaimer;

    const newIsMenuVisible = !showWelcome && !showDisclaimer;
    useEffect(() => {
        dispatch(setIsMenuVisible({ isMenuVisible: newIsMenuVisible }));
    }, [dispatch, newIsMenuVisible]);

    if (showWelcome) {
        const welcomeSeenCb = () => {
            dispatch(updateProfile({ welcomeSeen: true }));
            history.replace('/');
        };

        return <Welcome closeCb={welcomeSeenCb} />;
    }

    const disclaimerSeenCb = () => dispatch(updateProfile({ disclaimerSeen: true }));
    return (
        <>
            {children}
            {showDisclaimer && <Disclaimer closeCb={disclaimerSeenCb} />}
            <InvitedToClinicModal />
        </>
    );
}

export default Onboarding;
