import { IAppState } from '../../app.types';
import ConstantsHelper from '../../../../helpers/ConstantsHelper';
import UtilityHelper from '../../../../helpers/UtilityHelper';
import UiHelper from '../../../../helpers/UiHelper';
import AnI18NextLibHelper from '../../../../helpers/AnI18NextLibHelper';

const fetchProfileRejected = (state: IAppState, { payload }: any) => {
    const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.fetchProfile);
    state.patient.disclaimerSeen = false;
    state.patient.welcomeSeen = false;
    state.errors[callKey] = UiHelper.CreateErrorMessage({
        level: ConstantsHelper.ErrorLevels.error,
        message: payload?.message ?? `${AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.invalidResponse')}`,
        title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.pwdProfileGet'),
    });
    state.loadingSemaphore--;
};

export default fetchProfileRejected;
