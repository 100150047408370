import React from 'react';
import clsx from 'clsx';

import { IChartPayload, ILegendEntry, ILegendSection, ITranslator } from '../../../types';
import styleGuide from '../../../styles/styleGuide.module.scss';
import ChartHelper from '../../../helpers/ChartHelper';

const renderIconEntryColor = (bodyEntry: ILegendEntry) =>
    bodyEntry.color && (
        <span
            className={styleGuide.icon}
            style={{
                backgroundColor: bodyEntry.color,
            }}
        ></span>
    );

const renderIconEntryImage = (bodyEntry: ILegendEntry, imageAlt: string) =>
    bodyEntry.img && <img src={bodyEntry.img} alt={imageAlt} />;

function ContributingEventChartLegend({
    insulinDeliveryDataAttributes,
    translate,
}: {
    insulinDeliveryDataAttributes: IChartPayload;
    translate: ITranslator;
}) {
    const translatorKeyPrefix = 'chartLegend.';
    const sections: ILegendSection[] = ChartHelper.GetInsulinLegendEntries(insulinDeliveryDataAttributes);

    return (
        sections?.length > 0 && (
            <div className={styleGuide.chartLegend}>
                <div className={styleGuide.legend} data-testid="chartLegend">
                    {sections.map((sectionEntry: ILegendSection, idx: number) => (
                        <div key={`section${idx}`}>
                            <div className={clsx(styleGuide.header)}>
                                {translate(`${translatorKeyPrefix}${sectionEntry.header}`)}
                            </div>
                            <div className={styleGuide.body}>
                                {sectionEntry.body.map((bodyEntry: ILegendEntry, idx2: number) => (
                                    <div key={`entry${idx}${idx2}`} className={clsx(styleGuide.row)}>
                                        <span className={styleGuide.left}>
                                            {renderIconEntryColor(bodyEntry)}
                                            {renderIconEntryImage(
                                                bodyEntry,
                                                translate(`${translatorKeyPrefix}altIconEntry`)
                                            )}
                                        </span>
                                        <span className={styleGuide.right}>
                                            <span className={styleGuide.icon}>
                                                {translate(`${translatorKeyPrefix}${bodyEntry.label}`)}
                                            </span>
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        )
    );
}

export default ContributingEventChartLegend;
