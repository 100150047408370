import React from 'react';
import { ResponsiveLine } from '@nivo/line';
import AutoSizer from 'react-virtualized-auto-sizer';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

import { PatternType } from '../../../model/patternType';
import { IBolusTracker, IBolusTrackerY, IChartEntryBloodGlucose, IChartPayload, ITranslator } from '../../../types';
import { IWindowSize } from '../../../store/ui/ui.types';
import { RootState } from '../../../store/store';
import { IAppState, IBolusBase, IInsight } from '../../../store/app/app.types';
import styleGeneral from '../../../styles/general.module.scss';
import styleGuide from '../../../styles/styleGuide.module.scss';
import ConstantsHelper from '../../../helpers/ConstantsHelper';
import DateTimeHelper from '../../../helpers/DateTimeHelper';
import StringHelper from '../../../helpers/StringHelper';
import UtilityHelper from '../../../helpers/UtilityHelper';
import ChartTooltipPopover from '../../common/chartTooltipPopover';
import EventHelper from '../../../helpers/EventHelper';

const chartId = 'chartContainerBloodGlucose';
const bolusWidthPixels = 33;
interface IScreenDetails {
    xAxisTicks: string[];
    haveDailyCrossOver: boolean;
    windowSize?: IWindowSize;
    marginLeft?: number;
    marginRight?: number;
}

const getLegendOffsetY = (insightPatternType: PatternType.PatternTypeEnum) => {
    let legendOffsetY = -1;

    if (insightPatternType === PatternType.PatternTypeEnum.High) {
        legendOffsetY = 166;
    } else {
        legendOffsetY = 4;
    }

    return legendOffsetY;
};

const getMarkerHorizontalOffsetY = (value1: number, value2: number) => {
    const diff = value1 - value2;
    const diffPct = Math.abs(Math.round(100 * (diff / value2)));
    const minGap = diffPct < 15 ? 5 : 0;
    const offsetDir = diff * -1 === Math.abs(diff) ? -1 : 1;

    return offsetDir * minGap;
};

const getMarkerDataSub2 = (legendOffsetY: number, xAxisTicks: string[], markerVerticalBase: any) => {
    const markerDailyBase = {
        ...markerVerticalBase,
        legendOrientation: 'horizontal',
        lineStyle: {
            ...markerVerticalBase.lineStyle,
            stroke: ConstantsHelper.Chart.colorMarkerVertXoverDaily,
        },
        textStyle: {
            fontFamily: 'OpenSans',
            fontSize: '12px',
            fontWeight: 'bold',
            fill: 'black',
            start: 100,
        },
        legendOffsetY,
        legendOffsetPreX: -80,
        legendOffsetPstX: 6,
    };

    const hourlyMarkerLast = xAxisTicks?.length > 0 && xAxisTicks[xAxisTicks.length - 1];

    return { markerDailyBase, hourlyMarkerLast };
};

const getMarkerDataSub = (
    legendOffsetY: number,
    xAxisTicks: string[],
    markerVerticalBase: any,
    bloodGlucoseDataAttributes: IChartPayload
) => {
    const { markerDailyBase, hourlyMarkerLast } = getMarkerDataSub2(legendOffsetY, xAxisTicks, markerVerticalBase);
    const daily = bloodGlucoseDataAttributes.crossOvers.daily;

    return [
        ...daily.map((m, idx) => ({
            ...markerDailyBase,
            legend:
                (idx > 0 && xAxisTicks?.length > 0 && m > xAxisTicks[0]) || idx > 1
                    ? DateTimeHelper.FormatDateForChart(bloodGlucoseDataAttributes?.crossOvers?.daily[idx - 1])
                    : '',
            legendOffsetX: markerDailyBase.legendOffsetPreX,
            lineStyle: { ...markerDailyBase.lineStyle, stroke: 'transparent' },
            value: new Date(m),
        })),
        ...daily
            .filter((m, idx) => idx > 0)
            .map((m) => ({
                ...markerDailyBase,
                legend: hourlyMarkerLast && m < hourlyMarkerLast ? DateTimeHelper.FormatDateForChart(m) : '',
                legendOffsetX: markerDailyBase.legendOffsetPstX,
                value: new Date(m),
            })),
    ];
};

const getMarkerData = (
    legendOffsetY: number,
    xAxisTicks: string[],
    haveDailyCrossOver: boolean,
    bloodGlucoseDataAttributes: IChartPayload
) => {
    const markerHorizontalBase = {
        axis: 'y',
        legendOrientation: 'horizontal',
        legendOffsetX: -24,
        legendOffsetY: 0,
        lineStyle: {
            strokeWidth: 1,
        },
        textStyle: {
            fontFamily: 'OpenSans',
            fontSize: '12px',
            fontWeight: 'bold',
            start: 0,
            backgroundColor: 'red',
        },
    };
    const markerVerticalBase = {
        axis: 'x',
        legendOrientation: 'vertical',
        lineStyle: {
            strokeWidth: 1,
            strokeDasharray: '170',
        },
    };
    const dailyMarkers = haveDailyCrossOver
        ? getMarkerDataSub(legendOffsetY, xAxisTicks, markerVerticalBase, bloodGlucoseDataAttributes)
        : [];

    return {
        markerHorizontalBase,
        markerVerticalBase,
        dailyMarkers,
    };
};

const getReadingsWithBoluses = (bloodGlucoseDataAttributes: IChartPayload) =>
    (
        bloodGlucoseDataAttributes?.data?.find((d: any) => d.id === ConstantsHelper.DataSeriesBolus)
            ?.data as IChartEntryBloodGlucose[]
    )?.filter((readingWithBoluses) => readingWithBoluses.boluses?.length > 0);

const prepBolusDataSub = (
    readingWithBoluses: IChartEntryBloodGlucose,
    bolusesEntry: IBolusBase,
    bolusEntryLast: IBolusTracker,
    bolusData: IBolusTracker[],
    secondsPerPixel: number,
    pixelsDistanceFromPrevBolus: number
) => {
    const bolusSubEntry: IBolusTrackerY = {
        bolusCorrection: bolusesEntry.bolusCorrection,
        bolusMeal: bolusesEntry.bolusMeal,
        bolusTotal: bolusesEntry.bolusTotal,
        bolusTime: bolusesEntry.bolusTime,
        bolusType: bolusesEntry.bolusType,
        carbs: bolusesEntry.carbs,
        x: readingWithBoluses.x,
    };

    if (bolusEntryLast) {
        pixelsDistanceFromPrevBolus =
            DateTimeHelper.GetDurationInSeconds(bolusEntryLast.xGroup, bolusSubEntry.x) / secondsPerPixel;
    }

    if (bolusEntryLast && pixelsDistanceFromPrevBolus <= bolusWidthPixels) {
        bolusEntryLast.xGroup = DateTimeHelper.GetTimeStampMidPoint([
            ...bolusEntryLast.y.map((e) => e.x),
            bolusSubEntry.x,
        ]);
    } else {
        bolusEntryLast = {
            homePos: { left: 0, top: 0 },
            xGroup: bolusSubEntry.x,
            y: [],
        };

        bolusData.push(bolusEntryLast);
    }

    bolusEntryLast.y.push(bolusSubEntry);

    return { bolusEntryLast, pixelsDistanceFromPrevBolus };
};

const prepBolusData = (
    bloodGlucoseDataAttributes: IChartPayload,
    bolusData: IBolusTracker[],
    rangeSecs: number,
    secondsPerPixel: number
) => {
    /* istanbul ignore else: there is no else  to cover */
    if (bolusData.length === 0 && rangeSecs > 0) {
        const readingsWithBoluses = getReadingsWithBoluses(bloodGlucoseDataAttributes);

        if (readingsWithBoluses.length > 0) {
            let bolusEntryLast: IBolusTracker;
            let pixelsDistanceFromPrevBolus = 0;

            readingsWithBoluses.forEach((readingWithBoluses) => {
                readingWithBoluses.boluses.forEach((bolusesEntry) => {
                    const bolusContainer = prepBolusDataSub(
                        readingWithBoluses,
                        bolusesEntry,
                        bolusEntryLast,
                        bolusData,
                        secondsPerPixel,
                        pixelsDistanceFromPrevBolus
                    );

                    bolusEntryLast = bolusContainer.bolusEntryLast;
                    pixelsDistanceFromPrevBolus = bolusContainer.pixelsDistanceFromPrevBolus;
                });
            });
        }
    }
};

const prepSettings = (bloodGlucoseDataAttributes: IChartPayload, insight: IInsight): any => {
    return {
        animate: true,
        areaBaselineValue: bloodGlucoseDataAttributes?.yCutOff,
        areaOpacity: 0.15,
        axisLeft: null,
        axisRight: null,
        axisTop: null,
        colors: [
            ConstantsHelper.Chart.colorInRange,
            ConstantsHelper.Chart.colorMarkerHorizLow,
            ConstantsHelper.Chart.colorMarkerHorizHigh,
            'transparent',
        ],
        crosshairType: 'cross',
        curve: 'basis',
        data: bloodGlucoseDataAttributes?.data,
        enableArea: true,
        enableGridX: false,
        enableGridY: false,
        enablePointLabel: false,
        enableSlices: false,
        lineWidth: 4,
        pointBorderColor: {
            from: 'color',
            modifiers: [['darker', 0.3]],
        },
        pointBorderWidth: 0,
        pointLabelYOffset: 20,
        pointSize: 0,
        theme: {
            axis: {
                ticks: {
                    text: {
                        fontFamily: 'OpenSans',
                        fontSize: '12px',
                        fill: 'black',
                    },
                },
            },
            tooltip: {
                container: {
                    position: 'relative',
                    left: '24px',
                    fontSize: '9pt',
                },
            },
        },
        useMesh: false,
        yFormat: '>-.0f',
        xScale: {
            max: bloodGlucoseDataAttributes?.windowEnd,
            min: bloodGlucoseDataAttributes?.windowBeg,
            type: 'time',
            format: '%Y-%m-%dT%H:%M:%S%Z',
            useUTC: false,
        },
        xFormat: 'time:%Y-%m-%dT%H:%M:%S%Z',
        axisBottom: {
            format: (d: any) => DateTimeHelper.FormatTimeForChart(`${d}`, true, false, true, true),
            tickSize: 8,
            tickValues: 'every 1 hour',
        },
        defs: [
            {
                id: 'gradientBlank',
                type: 'linearGradient',
                colors: [{ color: 'transparent' }],
            },
        ],
        fill: [
            {
                match: {
                    id: ConstantsHelper.DataSeriesNormal,
                },
                id: 'gradientBlank',
            },
            {
                match: {
                    id:
                        insight.patternType === PatternType.PatternTypeEnum.High
                            ? ConstantsHelper.DataSeriesLow
                            : ConstantsHelper.DataSeriesHigh,
                },
                id: 'gradientBlank',
            },
        ],
    };
};

const getChartSettings = (
    app: IAppState,
    translate: ITranslator,
    bloodGlucoseDataAttributes: IChartPayload,
    insight: IInsight,
    bolusData: IBolusTracker[],
    screenDetails: IScreenDetails
): any => {
    const { windowSize, marginLeft, marginRight, haveDailyCrossOver, xAxisTicks } = screenDetails;
    const highEgvMgDlThreshold: number =
        insight?.highEgvMgDlThreshold < 1 ? ConstantsHelper.ReadingsGoodEgvMax : insight?.highEgvMgDlThreshold;
    const lowEgvMgDlThreshold: number =
        insight?.lowEgvMgDlThreshold < 1 ? ConstantsHelper.ReadingsGoodEgvMin : insight?.lowEgvMgDlThreshold;
    const pointMaxY = Math.max(bloodGlucoseDataAttributes?.yMax + 40, highEgvMgDlThreshold);
    const pointMinY = Math.min(bloodGlucoseDataAttributes?.yMin, ConstantsHelper.ReadingsRangeEgvMin - 40);
    const legendOffsetY = getLegendOffsetY(insight?.patternType);
    const { markerHorizontalBase, markerVerticalBase, dailyMarkers } = getMarkerData(
        legendOffsetY,
        xAxisTicks,
        haveDailyCrossOver,
        bloodGlucoseDataAttributes
    );
    const rangeSecs = DateTimeHelper.GetDurationInSeconds(
        bloodGlucoseDataAttributes?.windowBeg,
        bloodGlucoseDataAttributes?.windowEnd
    );
    const maxPixels = windowSize?.width ? windowSize.width - marginLeft - marginRight : 1;
    const secondsPerPixel = rangeSecs / (maxPixels === 0 ? 1 : maxPixels);

    prepBolusData(bloodGlucoseDataAttributes, bolusData, rangeSecs, secondsPerPixel);

    return {
        ...prepSettings(bloodGlucoseDataAttributes, insight),
        margin: {
            top: 0,
            right: marginRight,
            bottom: 56,
            left: marginLeft,
        },
        markers: [
            ...(bloodGlucoseDataAttributes?.xMarkers ?? []).map((m) => ({
                ...markerVerticalBase,
                lineStyle: {
                    ...markerVerticalBase.lineStyle,
                    stroke: 'transparent',
                },
                value: new Date(m),
            })),
            ...dailyMarkers,
            {
                ...markerHorizontalBase,
                legend: EventHelper.GetEgvLabel(translate, bloodGlucoseDataAttributes?.egvMax, true),
                lineStyle: { ...markerHorizontalBase.lineStyle, stroke: 'transparent' },
                textStyle: { ...markerHorizontalBase.textStyle, fill: ConstantsHelper.Chart.colorMarkerHorizHigh },
                value: bloodGlucoseDataAttributes?.egvMax,
                legendOffsetY: getMarkerHorizontalOffsetY(bloodGlucoseDataAttributes?.egvMax, highEgvMgDlThreshold),
            },
            {
                ...markerHorizontalBase,
                legend: highEgvMgDlThreshold,
                lineStyle: { ...markerHorizontalBase.lineStyle, stroke: ConstantsHelper.Chart.colorMarkerHorizHigh },
                textStyle: { ...markerHorizontalBase.textStyle, fill: ConstantsHelper.Chart.colorMarkerHorizHigh },
                value: highEgvMgDlThreshold,
                legendOffsetY: getMarkerHorizontalOffsetY(highEgvMgDlThreshold, bloodGlucoseDataAttributes?.egvMax),
            },
            {
                ...markerHorizontalBase,
                legend: lowEgvMgDlThreshold,
                lineStyle: { ...markerHorizontalBase.lineStyle, stroke: ConstantsHelper.Chart.colorMarkerHorizLow },
                textStyle: { ...markerHorizontalBase.textStyle, fill: ConstantsHelper.Chart.colorMarkerHorizLow },
                value: lowEgvMgDlThreshold,
                legendOffsetY: getMarkerHorizontalOffsetY(lowEgvMgDlThreshold, bloodGlucoseDataAttributes?.egvMin),
            },
            {
                ...markerHorizontalBase,
                legend: EventHelper.GetEgvLabel(translate, bloodGlucoseDataAttributes?.egvMin, true),
                lineStyle: { ...markerHorizontalBase.lineStyle, stroke: 'transparent' },
                textStyle: { ...markerHorizontalBase.textStyle, fill: ConstantsHelper.Chart.colorMarkerHorizLow },
                value: bloodGlucoseDataAttributes?.egvMin,
                legendOffsetY: getMarkerHorizontalOffsetY(bloodGlucoseDataAttributes?.egvMin, lowEgvMgDlThreshold),
            },
        ],
        pointSymbol: ({ datum }: any) =>
            CustomSymbolHandler(
                datum,
                bolusData,
                bloodGlucoseDataAttributes?.windowBeg,
                bloodGlucoseDataAttributes?.windowEnd,
                secondsPerPixel,
                translate
            ),
        yScale: {
            max: pointMaxY,
            min: pointMinY,
            reverse: false,
            stacked: false,
            type: 'linear',
        },
        defs: [
            {
                id: 'gradientBlank',
                type: 'linearGradient',
                colors: [{ color: 'transparent' }],
            },
        ],
        fill: [
            {
                match: {
                    id: ConstantsHelper.DataSeriesNormal,
                },
                id: 'gradientBlank',
            },
            {
                match: {
                    id:
                        insight.patternType === PatternType.PatternTypeEnum.High
                            ? ConstantsHelper.DataSeriesLow
                            : ConstantsHelper.DataSeriesHigh,
                },
                id: 'gradientBlank',
            },
        ],
    };
};

const xOffsetIfTooCloseToEdge = (
    xOffset: number,
    secondsPerPixel: number,
    xBolus: string,
    xEdge: string,
    isLeftEdge: boolean
): number => {
    let xOffsetAdj = xOffset;
    const pixelsToEdge = Math.ceil(Math.abs(DateTimeHelper.GetDurationInSeconds(xBolus, xEdge)) / secondsPerPixel);
    const pixelShortFall = bolusWidthPixels / 2 - pixelsToEdge;

    if (pixelShortFall > 0) {
        xOffsetAdj += (isLeftEdge ? 1 : -1) * pixelShortFall;
    }

    return xOffsetAdj;
};

function ChartTooltipPopoverMain(bolusEntry: IBolusTracker, yOffset: number, xOffset: number) {
    return (
        <g
            key={`keyBolusIcon${bolusEntry.homePos.left}`}
            pointerEvents="all"
            id="BolusIcon"
            fill="black"
            stroke="white"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
            fillOpacity={1.0}
            transform={`translate(${xOffset}, ${yOffset})`}
            className={styleGuide.bolusIcon}
        >
            <path d="M 9.5,10 m -16.5,0 a 16.5,16.5 0 1,0 33,0 a 16.5,16.5 0 1,0 -33,0" />
            <path
                strokeWidth="2.4"
                d="M1.294 0.404L7.438 0.404"
                transform="translate(-138 -656) translate(15 569) translate(115 80) translate(1) translate(7 7) translate(5.146 1.98)"
            />
            <path
                strokeWidth="2.16"
                d="M1.294 4.932L7.438 4.932"
                transform="translate(-138 -656) translate(15 569) translate(115 80) translate(1) translate(7 7) translate(5.146 1.98)"
            />
            <path
                strokeWidth="2.4"
                d="M8.57 5.298L4.285 3.072 0 5.298 0 14.947 8.57 14.947zM4.285.809L4.285 2.587"
                transform="translate(-138 -656) translate(15 569) translate(115 80) translate(1) translate(7 7) translate(5.146 1.98)"
            />
            <text
                x={bolusEntry.y.length > 1 ? -8 : -3}
                y="38"
                fontFamily="arial"
                fontSize="10"
                fill="white"
                stroke="black"
            >
                {`${StringHelper.NumberFormatter(UtilityHelper.RoundNumber(bolusEntry.y[0].bolusTotal, 2))} U${
                    bolusEntry.y.length > 1 ? '…' : ''
                }`}
            </text>
            {bolusEntry.y.length > 1 && (
                <g
                    key={`keyBolusIcon${bolusEntry.homePos.left}`}
                    id="BolusIcon"
                    fill="black"
                    stroke="white"
                    fillRule="evenodd"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fillOpacity={1.0}
                    transform="translate(-9, 0)"
                >
                    <path
                        d={
                            bolusEntry.y.length < 10
                                ? 'm 32.0,-6 m -8.25,0 a 8.25,8.25 0 1,0 16.5,0 a 8.25,8.25 0 1,0 -16.5,0'
                                : 'm 32.0,-6 m -8.25,0 a 8.25,6.75 0 1,0 22.5,0 a 8.25,6.75 0 1,0 -22.5,0'
                        }
                    />
                    <text x="29" y="-3" fontFamily="arial" fontSize="10" fill="black" stroke="white">
                        {bolusEntry.y.length}
                    </text>
                </g>
            )}
        </g>
    );
}

function ChartTooltipPopoverBubble(translate: ITranslator, bolusEntry: IBolusTracker) {
    return (
        <div id="bolusTooltip" className={styleGuide.chartTooltipBolus}>
            {bolusEntry.y.map((p, idx) => (
                <div key={`${p.x}${idx}`}>
                    {idx > 0 && <div className={styleGuide.separator}></div>}
                    <div className={styleGuide.item}>
                        <div className={clsx(styleGuide.left, styleGuide.time)}>
                            {DateTimeHelper.FormatTimeForChart(p.bolusTime, false, true)}
                        </div>
                        <div className={styleGuide.right}>
                            {p.bolusType?.toLowerCase() === 'extended' && translate('extendedBolus')}
                        </div>
                    </div>
                    <div className={styleGuide.item}>
                        <span className={styleGuide.left}>{translate('carbs')}</span>
                        <span className={styleGuide.right}>
                            {StringHelper.NumberFormatter(UtilityHelper.RoundNumber(p.carbs, 2))}{' '}
                            {ConstantsHelper.UnitsTable.carbs}
                        </span>
                    </div>
                    <div className={styleGuide.totals}>
                        <span className={styleGuide.left}>{translate('totalBolus')}</span>
                        <span className={styleGuide.right}>
                            {StringHelper.NumberFormatter(UtilityHelper.RoundNumber(p.bolusTotal, 2))}{' '}
                            {ConstantsHelper.UnitsTable.bolus}
                        </span>
                    </div>
                </div>
            ))}
        </div>
    );
}

function CustomSymbolHandler(
    datum: any,
    bolusData: IBolusTracker[],
    xMin: string,
    xMax: string,
    secondsPerPixel: number,
    translate: ITranslator
) {
    if (datum?.boluses?.length) {
        const bolusEntry: IBolusTracker = bolusData.find(
            (b) => DateTimeHelper.GetTimeStampInLocalFormat(b.y[0].x) === datum.xFormatted
        );

        if (bolusEntry) {
            let yOffset = 0;
            let xOffset = DateTimeHelper.GetDurationInSeconds(bolusEntry.y[0].x, bolusEntry.xGroup) / secondsPerPixel;

            xOffset += -bolusWidthPixels / 2;

            let xOffsetAdj = xOffsetIfTooCloseToEdge(xOffset, secondsPerPixel, bolusEntry.xGroup, xMin, true);
            let xOffsetAdjNum = UtilityHelper.IsNumeric(xOffsetAdj) ? xOffsetAdj : 0;
            const xOffsetNum = UtilityHelper.IsNumeric(xOffset) ? xOffset : 0;

            if (xOffsetAdjNum === xOffsetNum) {
                xOffsetAdj = xOffsetIfTooCloseToEdge(xOffset, secondsPerPixel, bolusEntry.xGroup, xMax, false);
                xOffsetAdjNum = xOffsetAdj;
            }

            if (xOffsetNum !== xOffsetAdjNum) {
                xOffset = xOffsetAdj;
                yOffset += bolusWidthPixels * 1.6;
            }

            return ChartTooltipPopover(
                () => ChartTooltipPopoverMain(bolusEntry, yOffset, xOffset),
                () => ChartTooltipPopoverBubble(translate, bolusEntry)
            );
        }
    }

    return null;
}

const bloodGlucoseRender = ({
    translate,
    xAxisTicks,
    app,
    bloodGlucoseDataAttributes,
    insight,
    marginLeft,
    marginRight,
    windowSize,
    bolusData,
    haveData,
    haveDailyCrossOver,
}: {
    translate: ITranslator;
    xAxisTicks: string[];
    app: IAppState;
    bloodGlucoseDataAttributes: IChartPayload;
    insight: IInsight;
    marginLeft: number;
    marginRight: number;
    windowSize: IWindowSize;
    bolusData: IBolusTracker[];
    haveData: boolean;
    haveDailyCrossOver: boolean;
}) => {
    if (haveData) {
        return (
            <div className={styleGeneral.chartGlucose}>
                <AutoSizer style={{ width: '100%', display: 'flex' }}>
                    {
                        /* istanbul ignore next: not rendered in test */
                        ({ width }: any) => (
                            <div id={chartId} className={styleGeneral.chartGlucoseModeInner}>
                                <ResponsiveLine
                                    key="chartMain"
                                    pointerEvents="all"
                                    {...getChartSettings(
                                        app,
                                        translate,
                                        bloodGlucoseDataAttributes,
                                        insight,
                                        bolusData,
                                        {
                                            windowSize: windowSize,
                                            marginLeft: marginLeft,
                                            marginRight: marginRight,
                                            haveDailyCrossOver,
                                            xAxisTicks,
                                        }
                                    )}
                                    width={width}
                                />
                            </div>
                        )
                    }
                </AutoSizer>
            </div>
        );
    } else {
        return (
            <div className={styleGuide.noData}>{translate('nodata', { tag: translate('chart.glucoseTimeline') })}</div>
        );
    }
};

function ContributingEventChartBloodGlucose({
    xAxisTicks,
    app,
    bloodGlucoseDataAttributes,
    insight,
    marginLeft,
    marginRight,
    translate,
}: {
    xAxisTicks: string[];
    app: IAppState;
    bloodGlucoseDataAttributes: IChartPayload;
    insight: IInsight;
    marginLeft?: number;
    marginRight?: number;
    translate: ITranslator;
}) {
    const windowSize = useSelector((state: RootState) => state.ui.windowSize);

    const bolusData: IBolusTracker[] = [];
    const haveData = bloodGlucoseDataAttributes?.data?.length > 0;
    const haveDailyCrossOver = bloodGlucoseDataAttributes?.crossOvers?.daily?.length > 1;

    return !bloodGlucoseDataAttributes ? null : (
        <div className={styleGuide.bloodGlucoseChart}>
            <div className={styleGuide.title}>
                <div className={styleGuide.line1}>{bloodGlucoseDataAttributes?.tag}</div>
                <div className={styleGuide.line2}>{bloodGlucoseDataAttributes?.tag2}</div>
            </div>

            {bloodGlucoseRender({
                translate,
                xAxisTicks,
                app,
                bloodGlucoseDataAttributes,
                insight,
                marginLeft,
                marginRight,
                windowSize,
                bolusData,
                haveData,
                haveDailyCrossOver,
            })}
        </div>
    );
}

export default ContributingEventChartBloodGlucose;
