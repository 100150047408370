import { IAppState } from '../../app.types';
import ConstantsHelper from '../../../../helpers/ConstantsHelper';
import UtilityHelper from '../../../../helpers/UtilityHelper';
import UiHelper from '../../../../helpers/UiHelper';
import AnI18NextLibHelper from '../../../../helpers/AnI18NextLibHelper';

const updateProfileRejected = (state: IAppState, action: any) => {
    const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.updateProfile, [
        `${action.meta?.arg?.disclaimerSeen}`,
        `${action.meta?.arg?.welcomeSeen}`,
        `${action.meta?.arg?.eulaAccepted}`,
        `${action.meta?.arg?.confidentialityAgreementAccepted}`,
    ]);
    state.patient.disclaimerSeen = false;
    state.patient.welcomeSeen = false;
    state.patient.eulaAccepted = false;
    state.patient.confidentialityAgreementAccepted = false;
    state.errors[callKey] = UiHelper.CreateErrorMessage({
        level: ConstantsHelper.ErrorLevels.error,
        message: `${AnI18NextLibHelper.Translate(
            state.anI18Nextlib,
            'errors.pwdProfilePutFailed'
        )}.\n${AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.reason')}:  ${action?.payload?.message}`,
        title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.pwdProfilePut'),
    });
    state.loadingSemaphore--;
};

export default updateProfileRejected;
