import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import Spinner from 'react-bootstrap/Spinner';
import React, { useState, useCallback, useEffect } from 'react';

import { allReportsRowCntActionCreator } from '../store/app/app.slice';
import { RootState } from '../store/store';
import { setAnchorAllReports } from '../store/ui/ui.slice';
import { appInitialState } from '../store/app/app.initialState';
import DateTimeHelper from '../helpers/DateTimeHelper';
import iconArrowRight from '../assets/images/icons-arrow-right.svg';
import NavigationHelper from '../helpers/NavigationHelper';
import styleGeneral from '../styles/general.module.scss';
import styleGuide from '../styles/styleGuide.module.scss';
import SystemHelper from '../helpers/SystemHelper';
import UiHelper from '../helpers/UiHelper';
import UtilityHelper from '../helpers/UtilityHelper';

const endMarker = 'allReportsEnd'; // Use to ensure all elements have rendered before accessing element anchors
const chunkSize = 15;

function AllReports() {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const anchorAllReports = useSelector((state: RootState) => state.ui.anchorAllReports);
    const app = SystemHelper.GetCleanState(
        useSelector((state: RootState) => state.app),
        appInitialState
    );

    const getFirstJoined = () =>
        !UtilityHelper.IsNull(app.patient.firstJoined) ? UtilityHelper.CryptoDecrypt(app.patient.firstJoined) : null;
    /* istanbul ignore next: dispatch callback function*/
    const clearAnchorEvent = () => dispatch(setAnchorAllReports({ anchorAllReports: undefined }));
    const [scrollActive1, setScrollActive1] = useState(false);
    const [scrollActive2, setScrollActive2] = useState(false);
    const [pastWeeksRange, setPastWeeksRange] = useState([]);
    const [renderableWeekRanges, setRenderableWeekRanges] = useState([]);
    const [rangeOffset, setRangeOffset] = useState(Math.max(app.allReportsRowCnt, chunkSize));

    const handleClickCb = (beg: string, end: string, anchorAllReports: string) =>
        UiHelper.setDashboardConfig(dispatch, beg, end, anchorAllReports);

    /* istanbul ignore next: jest-dom doesn't actually render the layout so scroll cannot be tested */
    const handleScroll = useCallback(
        (event: any) => {
            const { scrollTop, scrollHeight, clientHeight } = event.target ?? undefined;
            const bottom = scrollHeight === Math.round(scrollTop + clientHeight);

            NavigationHelper.HandleScroll(
                undefined,
                scrollActive1,
                scrollActive2,
                setScrollActive1,
                setScrollActive2,
                scrollTop
            );

            // If user has reached to bottom of the page and there are still weeks available to render
            if (bottom && pastWeeksRange && pastWeeksRange.length > renderableWeekRanges.length) {
                const nextOffset = rangeOffset + chunkSize;
                const nextRanges = pastWeeksRange.slice(rangeOffset, nextOffset);
                setRenderableWeekRanges(renderableWeekRanges.concat(nextRanges));
                setRangeOffset(nextOffset);
                dispatch(allReportsRowCntActionCreator({ allReportsRowCnt: nextOffset }));
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            scrollActive1,
            scrollActive2,
            setScrollActive1,
            setScrollActive2,
            renderableWeekRanges,
            pastWeeksRange,
            rangeOffset,
            setRenderableWeekRanges,
            setRangeOffset,
        ]
    );

    useEffect(() => {
        SystemHelper.AppInsightsEvent(app, 'AllReports()', 'Mounted');
    }, [app]);

    useEffect(() => {
        /* istanbul ignore else: No else condition */
        if (app.patient.receivedData) {
            const firstJoinedDecrypted = getFirstJoined();

            if (firstJoinedDecrypted) {
                const pastWeeksRangeTillDate = DateTimeHelper.GetPastWeekRangesTillDate(firstJoinedDecrypted);

                setPastWeeksRange(pastWeeksRangeTillDate);
                setRenderableWeekRanges(pastWeeksRangeTillDate.slice(0, Math.max(app.allReportsRowCnt, 15)));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [app.patient.receivedData, app.dashboardEnd, app.patient.firstJoined]);

    useEffect(() => {
        if (anchorAllReports && NavigationHelper.ElementExists(endMarker)) {
            NavigationHelper.ScrollIntoView(anchorAllReports, clearAnchorEvent);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    });

    return (
        <div
            data-testid="all-report-container"
            className={clsx(styleGeneral.body, styleGuide.allReports)}
            onScroll={(event) => handleScroll(event)}
        >
            <div className={clsx(styleGuide.report, styleGeneral.stickToTopLevel1)} data-testid="insights">
                <div className={clsx(styleGuide.header, styleGeneral.dashboardZoomInScroll1OffScroll2Off)}>
                    <div className={clsx(styleGuide.heading, scrollActive1 && styleGeneral.inScroll)}>
                        {t('allReports.title')}
                    </div>
                </div>
            </div>
            {renderableWeekRanges?.length > 0 ? (
                <div>
                    {renderableWeekRanges.map((ts, idx) => {
                        const anchor = `allReports${idx}`;
                        const { beg, end } = DateTimeHelper.GetDateRangeUtc(ts?.beg, ts?.end);

                        return (
                            <div id={anchor} key={anchor} className={styleGuide.weekCell}>
                                <Link
                                    data-testid={'allReportsSeeWeek'}
                                    className={styleGuide.link}
                                    to={NavigationHelper.GenUrlDashboard(beg, end, true)}
                                    onClick={() => handleClickCb(beg, end, anchor)}
                                >
                                    <div
                                        className={clsx(
                                            styleGuide.whiteBackground,
                                            styleGuide.card,
                                            styleGuide.elevated
                                        )}
                                    >
                                        <div className={styleGuide.main}>
                                            <div className={styleGuide.dateRange}>
                                                {DateTimeHelper.FormatAllReportsWeekRange(ts)}
                                            </div>

                                            <div className={styleGuide.dateyear}>
                                                {DateTimeHelper.GetDateYear(ts.beg, ts.end)}
                                            </div>

                                            <img
                                                alt={t('more')}
                                                className={styleGuide.cardIconArrowRight}
                                                src={iconArrowRight}
                                            />
                                        </div>
                                    </div>
                                </Link>
                                {idx === renderableWeekRanges.length - 1 && (
                                    <span id={endMarker} className={styleGeneral.hide}></span>
                                )}
                            </div>
                        );
                    })}
                    {pastWeeksRange.length > renderableWeekRanges.length && (
                        <Spinner className={styleGeneral.spinnerSmall} animation="border" role="status" />
                    )}
                    <div className={styleGeneral.verticalSpacer}></div>
                </div>
            ) : (
                <div className={styleGuide.noReportsYet}>
                    <div className={styleGuide.noReports}>{t('allReports.noWeeklyInsights')}</div>
                    <div>{t('allReports.noWeeklyInsightsYet')}</div>
                </div>
            )}
        </div>
    );
}

export default AllReports;
