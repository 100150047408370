import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

import { InsightInsightsProp } from '../../model/insightInsightsProp';
import { ICallback, IDayToFlag, IInsightAttr } from '../../types';
import { IAppState, IInsight } from '../../store/app/app.types';
import BtnPrimary from '../common/btnPrimary';
import styleGuide from '../../styles/styleGuide.module.scss';
import styleGeneral from '../../styles/general.module.scss';
import NavigationHelper from '../../helpers/NavigationHelper';

function InsightCard({
    app,
    anchor,
    cbClickFn,
    insight,
    insightAttributes,
    flaggedDay,
}: {
    app: IAppState;
    anchor: string;
    cbClickFn: ICallback<void>;
    insight: IInsight;
    insightAttributes: IInsightAttr;
    flaggedDay: IDayToFlag[];
}) {
    const { t } = useTranslation();

    const subTitleFlds: string[] = insightAttributes.scrn1SubTitle?.split('[boldToggle]');
    const renderSubtitlesInner = (e: string, idx: number) => {
        if (idx % 2 !== 0) {
            return <b key={`subTitleFlds${idx}`}>{e}</b>;
        }

        return <span key={`subTitleFlds${idx}`}>{e}</span>;
    };
    const renderSubtitles = () =>
        insight.insightType !== InsightInsightsProp.InsightTypeEnum.Bolus &&
        !!subTitleFlds && (
            <div className={clsx(styleGuide.ParagraphLeftBlack, styleGuide.subHeading)}>
                {subTitleFlds.map((e, idx) => renderSubtitlesInner(e, idx))}
            </div>
        );

    return !insight ? null : (
        <div id={anchor} key={anchor} className={clsx(styleGeneral.main, styleGuide.InsightCard)}>
            <div className={clsx(styleGuide.whiteBackground, styleGuide.card)}>
                <div className={styleGuide.headerSummary}>
                    <div className={styleGuide.dayCount}>
                        <Grid
                            alignItems="center"
                            className={clsx(styleGuide.titleFrame, styleGuide.titleContainer)}
                            container
                            spacing={1}
                        >
                            <Grid item>
                                <img
                                    src={insightAttributes.iconObj}
                                    className={styleGuide.icon}
                                    alt={`${insightAttributes.iconAlt} icon`}
                                />
                            </Grid>
                            <Grid item>
                                <div className={styleGuide.heading}>{insightAttributes.scrn1Title}</div>

                                {renderSubtitles()}
                            </Grid>
                        </Grid>

                        <div className={clsx(styleGeneral.table, styleGuide.row3, styleGuide.dayTilesRow)}>
                            <Grid className={clsx(styleGuide.dayTiles)} item xs={12}>
                                <div>
                                    <div>
                                        <Grid
                                            className={styleGuide.dayTitlesContainer}
                                            container
                                            justifyContent="center"
                                            spacing={0.55}
                                            wrap="nowrap"
                                        >
                                            {flaggedDay.map((day, idx) => (
                                                <Grid
                                                    className={styleGuide.dayTileColumn}
                                                    item
                                                    key={`flaggedDay${idx}`}
                                                    sm="auto"
                                                >
                                                    <div
                                                        className={clsx(
                                                            styleGuide.tile,
                                                            day.flagged
                                                                ? insightAttributes.styleAlt
                                                                : styleGuide.dayBoxGray
                                                        )}
                                                    >
                                                        {day.dayLetter}
                                                    </div>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </div>
                                </div>
                            </Grid>
                        </div>

                        <Grid item xs={12}>
                            <BtnPrimary
                                caption={t('viewDetails')}
                                cbClickFn={(payload: any) => cbClickFn(payload)}
                                payload={{
                                    insightId: insight.insightId,
                                    insightType: insight.insightType,
                                    patternType: insight.patternType,
                                    anchorInsight: anchor,
                                }}
                                url={NavigationHelper.GenUrlInsight(
                                    app.dashboardBeg,
                                    app.dashboardEnd,
                                    app.dashboardHasReferrer,
                                    insight.insightId
                                )}
                            />
                        </Grid>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InsightCard;
