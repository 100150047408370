import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useLayoutEffect, useState, useMemo } from 'react';
import clsx from 'clsx';

import { sendAzureEvent } from '../helpers/appInsights';
import { dashboardMetaDataActionCreator, insightIdActionCreator } from '../store/app/app.slice';
import { RootState } from '../store/store';
import { setAnchorEvent } from '../store/ui/ui.slice';
import { appInitialState } from '../store/app/app.initialState';
import UtilityHelper from '../helpers/UtilityHelper';
import SystemHelper from '../helpers/SystemHelper';
import styleGeneral from '../styles/general.module.scss';
import styleGuide from '../styles/styleGuide.module.scss';
import BtnBack from '../components/common/btnBack';
import ConstantsHelper from '../helpers/ConstantsHelper';
import NavigationHelper from '../helpers/NavigationHelper';
import UiHelper from '../helpers/UiHelper';
import DateTimeHelper from '../helpers/DateTimeHelper';
import LoyaltyInsulinUsageBlock from '../components/dashboard/loyalty/loyaltyInsulinUsageBlock';
import LoyaltyInsulinModeUsageChart from '../components/dashboard/loyalty/LoyaltyInsulinModeUsageChart';
import LoyaltyInsulinUsageAvgDaily from '../components/dashboard/loyalty/loyaltyInsulinUsageAvgDaily';
import WeeklyTrend from '../components/weeklyTrend/WeeklyTrend';
import LoyaltyInsulinUsageFactors from '../components/dashboard/loyalty/loyaltyInsulinUsageFactors';

function LoyaltyInsulinUsageInsights(props: any) {
    const dispatch = useDispatch();

    const app = SystemHelper.GetCleanState(
        useSelector((state: RootState) => state.app),
        appInitialState
    );
    const anchorEvent = useSelector((state: RootState) => state.ui.anchorEvent);

    const translate = (key: string, subs?: any) => UiHelper.Translate(app, key, subs);
    const patient = app.patient;
    const { dashboardBeg, dashboardEnd, dashboardHasReferrer, insightId } = props?.match?.params ?? {};
    const handleScroll = (event: any) =>
        NavigationHelper.HandleScroll(
            undefined,
            scrollActive1,
            undefined,
            setScrollActive1,
            undefined,
            event?.target?.scrollTop
        );

    useEffect(() => {
        if (
            !(
                app.dashboardBeg === dashboardBeg &&
                app.dashboardEnd === dashboardEnd &&
                app.dashboardHasReferrer === (dashboardHasReferrer === 'true')
            )
        ) {
            dispatch(
                dashboardMetaDataActionCreator({
                    dashboardBeg,
                    dashboardEnd,
                    dashboardHasReferrer: dashboardHasReferrer === 'true',
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dashboardBeg, dashboardEnd, dashboardHasReferrer]);

    useEffect(() => {
        if (app.insightId !== insightId) {
            dispatch(insightIdActionCreator({ insightId }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [insightId]);

    const dashboard = app.currentDashboard;
    const insight = app.cachedInsights[app.insightId];

    const clearAnchorEvent = () => dispatch(setAnchorEvent({ anchorEvent: undefined }));

    const [scrollActive1, setScrollActive1] = useState(false);
    useLayoutEffect(() => {
        SystemHelper.AppInsightsEvent(app, 'InsightDetails()', 'Mounted');
        sendAzureEvent(ConstantsHelper.analyticsEventNames.VIEW_INSULIN_USAGE_DETAILS, {
            weekOf: app?.dashboardBeg,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (patient.receivedData && !(app.dashboardEnd && app.insightId && dashboard?.insightIds)) {
            const payload = UiHelper.GetInsightsDateRange(dispatch, app);

            if (payload.beg && payload.end) {
                UiHelper.ClearErrors(dispatch);
                UiHelper.FetchDashboard(dispatch, payload.beg, payload.end, app);
                UiHelper.FetchDashboardInsulinUsage(dispatch, payload.beg, payload.end, app);
                UiHelper.FetchDashboardInsulinUsageTrend(dispatch, payload.beg, payload.end, app);
                UiHelper.FetchDashboardSummary(dispatch, payload.beg, payload.end, app);
                UiHelper.FetchDashboardSummaryGlucoseTrend(dispatch, payload.beg, payload.end, app);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patient.receivedData, app.patient?.confidentialityAgreementAccepted, app.patient?.eulaAccepted]);

    useEffect(() => {
        if (anchorEvent && insight) {
            NavigationHelper.ScrollIntoView(anchorEvent, clearAnchorEvent);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [anchorEvent, insight]);

    const shouldDisplayInsulinUsageInformation = useMemo(() => {
        const insulinUsageWeeks = UtilityHelper.ToNumeric(
            SystemHelper.GetRuntimeConfig('REACT_APP_INSULIN_USAGE_WEEKS')
        );
        return app.currentInsulinUsage?.weeklyTrend?.weeks?.length < insulinUsageWeeks;
    }, [app.currentInsulinUsage]);

    if (!app?.currentInsulinUsage?.dailyUsage) {
        return null;
    }

    return (
        <div
            data-testid="loyalty-congrats-container"
            className={styleGeneral.body}
            onScroll={(event) => handleScroll(event)}
        >
            <div className={styleGeneral.stickToTopBack}>
                <BtnBack
                    url={NavigationHelper.GenUrlDashboard(app.dashboardBeg, app.dashboardEnd, app.dashboardHasReferrer)}
                />
            </div>
            <div
                className={clsx(styleGuide.report, styleGuide.solidBgWhite, styleGeneral.stickToTopLevel2)}
                data-testid="loyalty-insulin"
            >
                <div
                    className={clsx(
                        styleGuide.header,
                        styleGuide.solidBgWhite,
                        styleGeneral.dashboardZoomInScroll1OffScroll2Off
                    )}
                >
                    <div className={clsx(styleGuide.loyaltyTitle, scrollActive1 && styleGeneral.inScroll2)}>
                        {translate('loyalty.insulin.title')}
                    </div>
                    <div className={clsx(styleGuide.dateRange, scrollActive1 && styleGuide.dateRangeShort)}>
                        <div className={clsx(styleGuide.caption, scrollActive1 && styleGeneral.inScroll)}>
                            {DateTimeHelper.FormatDateRange(app.dashboardEnd)}
                        </div>
                    </div>
                </div>
            </div>
            <div className={styleGuide.solidBg} data-testid="loyalty-insulin-frame">
                <div className={clsx(styleGuide.insightsFullWidthFirst, styleGeneral.dashboardSummary)}>
                    <div className={clsx(styleGuide.InsightCard, styleGuide.insightInsulin)}>
                        <div className={styleGuide.whiteBackground}>
                            <LoyaltyInsulinUsageBlock
                                translate={translate}
                                tokenHeader={'loyalty.insulin.weeklyTrend.title'}
                                tokenBody={'loyalty.insulin.weeklyTrend.block'}
                                preSeparate={false}
                            />
                            <WeeklyTrend app={app} translate={translate} />
                        </div>
                    </div>
                </div>
                <div className={clsx(styleGuide.insightsFullWidth, styleGeneral.dashboardSummary)}>
                    <div className={clsx(styleGuide.InsightCard, styleGuide.insightInsulin)}>
                        <div className={styleGuide.whiteBackground}>
                            <LoyaltyInsulinUsageBlock
                                translate={translate}
                                tokenHeader={'loyalty.insulin.totalDailyInsulinRatio.title'}
                                tokenInfo={'loyalty.insulin.totalDailyInsulinRatio.info'}
                                tokenBody={
                                    shouldDisplayInsulinUsageInformation
                                        ? 'loyalty.insulin.totalDailyInsulinRatio.totalDailyInsulinBlock'
                                        : ''
                                }
                                weekOf={app?.dashboardBeg}
                            />
                            <LoyaltyInsulinModeUsageChart app={app} showUnits />
                        </div>
                    </div>
                </div>
                <div className={clsx(styleGuide.insightsFullWidth, styleGeneral.dashboardSummary)}>
                    <div className={clsx(styleGuide.InsightCard, styleGuide.insightInsulin)}>
                        <div className={styleGuide.whiteBackground}>
                            <div className={styleGuide.header}>
                                <LoyaltyInsulinUsageBlock
                                    translate={translate}
                                    tokenHeader={'loyalty.insulin.avgDailyBolus.title'}
                                    tokenBody={
                                        shouldDisplayInsulinUsageInformation
                                            ? 'loyalty.insulin.avgDailyBolus.block'
                                            : ''
                                    }
                                    preSeparate={false}
                                />
                                <LoyaltyInsulinUsageAvgDaily app={app} translate={translate} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <LoyaltyInsulinUsageFactors app={app} translate={translate} />
        </div>
    );
}

export default LoyaltyInsulinUsageInsights;
