import { IAppState } from './app.types';

import AnI18NextLibHelper from '../../helpers/AnI18NextLibHelper';
import ConstantsHelper from '../../helpers/ConstantsHelper';

export const appInitialState: IAppState = {
    activeHttpCalls: {},
    allLoaded: false,
    allReportsRowCnt: 0,
    anI18Nextlib: AnI18NextLibHelper.loadLang('en'),
    calendarDate: undefined,
    errors: {
        general: {
            level: ConstantsHelper.ErrorLevels.clear,
            message: '',
            title: '',
        },
    },
    idTag: '20230507',
    isPolling: false,
    loadingSemaphore: 0,
    message: undefined,
    reloadInProgress: undefined,
    signInUrl: undefined,
    versionBackEnd: undefined,
    endpointPdfUrlPrefix: undefined,
    endpointPwdUrlPrefix: undefined,
    hcpAppUrl: undefined,

    // TODO: Move the below states to their respective slices.

    // Clinics
    clinics: [],

    // Dashboard
    currentDashboard: {},
    dashboardBeg: undefined,
    dashboardEnd: undefined,
    dashboardHasReferrer: false,

    // Insights
    cachedInsights: {},
    insightId: undefined,

    // Insight Event
    currentReadingCombo: {},

    // Learn Material OP5 Progress
    currentLearningMaterialProgress: { id: '', data: {} },

    // Learning Material Resources
    currentLearningMaterialResource: { id: '', data: {} },

    // Dashboard Insulin Usage & Dashboard Insulin Usage Trend
    currentInsulinUsage: {},

    // Dashboard Summary
    currentSummary: {},

    // Dashboard Summary Glucose Trend
    currentSummaryGlucoseTrend: {},

    // Contributing Event
    contributingEventId: undefined,
    contributingEventBeg: undefined,
    contributingEventEnd: undefined,
    readingsInfo: {
        fillGaps: false,
    },

    // Profile
    patient: {
        reportDaysAvailableString: undefined,
        isEnrolled: false,
        isUnenrolled: false,
        isOnboarded: false,
        receivedData: false,
        eulaAccepted: false,
        confidentialityAgreementAccepted: false,
        userType: undefined,
    },
};
