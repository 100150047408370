import { ResponsiveBullet } from '@nivo/bullet';
import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import clsx from 'clsx';

import ContributingEventChartLegend from './ContributingEventChartLegend';

import {
    IBulletChartData,
    IBulletPoint,
    IChartCallbackParams,
    IChartPayload,
    ILegendEntry,
    ITranslator,
} from '../../../types';
import { RootState } from '../../../store/store';
import { setShowLegend } from '../../../store/ui/ui.slice';
import { setValuesFromStateInLocalStorage } from '../../../helpers/SessionHelper';
import { IAppState } from '../../../store/app/app.types';
import styleGeneral from '../../../styles/general.module.scss';
import styleGuide from '../../../styles/styleGuide.module.scss';
import ConstantsHelper from '../../../helpers/ConstantsHelper';
import NavigationHelper from '../../../helpers/NavigationHelper';
import UiHelper from '../../../helpers/UiHelper';
import ChartTooltipPopover from '../../../components/common/chartTooltipPopover';
import ChartHelper from '../../../helpers/ChartHelper';

const legendMarkerBeg = 'legendMarkerBeg'; // Use to ensure legend is shown correctly
const legendMarkerEnd = 'legendMarkerEnd'; // Use to ensure legend is shown correctly
let insulinDeliveryDataAttributesGlobal: IChartPayload = null;
let translateGlobal: ITranslator = null;

const chartTooltipPopoverItemIconColor = (entry: ILegendEntry) =>
    entry.color && <span className={styleGuide.dot} style={{ backgroundColor: entry.color }}></span>;

const chartTooltipPopoverItemIconImage = (entry: ILegendEntry) =>
    entry.img && (
        <span className={styleGuide.icon}>
            <img src={entry.img} alt="legend entry icon" />
        </span>
    );
const chartTooltipPopoverBubble = (params: IChartCallbackParams): JSX.Element => {
    const fallbackValue = (params?.data as any)?.value;
    const entriesInscrope = ChartHelper.GetInsulinTooltipEntries(
        insulinDeliveryDataAttributesGlobal,
        params?.data?.v0 ?? fallbackValue,
        params?.data?.v1 ?? fallbackValue
    ).filter((entry) => entry.test);

    return entriesInscrope.length > 0 ? (
        <div id="insulinTooltip" className={styleGuide.chartTooltipInsulin}>
            {entriesInscrope.map((entry, idx) => (
                <div key={`insulinTooltip_${idx}`} className={styleGuide.item}>
                    <span className={styleGuide.left}>
                        {chartTooltipPopoverItemIconColor(entry)}
                        {chartTooltipPopoverItemIconImage(entry)}
                    </span>
                    <span className={styleGuide.right}>{translateGlobal(`chartLegend.${entry.label}`)}</span>
                </div>
            ))}
        </div>
    ) : null;
};
const getCustomRange = (params: IChartCallbackParams): JSX.Element => {
    const color =
        insulinDeliveryDataAttributesGlobal.trackerRange?.length > params.index
            ? insulinDeliveryDataAttributesGlobal.trackerRange[params.index].payload
            : ConstantsHelper.Chart.colorTransparent;

    return (
        <rect
            x={params.x}
            y={params.y}
            width={params.width}
            height={params.height}
            stroke={color === ConstantsHelper.Chart.colorTransparent ? ConstantsHelper.Chart.colorBorder : color}
            fill={color}
            strokeWidth={1}
        />
    );
};
const getCustomMeasure = (params: IChartCallbackParams): JSX.Element => {
    const top = -12.75;
    const radius = 5;
    const color =
        insulinDeliveryDataAttributesGlobal.trackerMeasure?.length > params.index
            ? insulinDeliveryDataAttributesGlobal.trackerMeasure[params.index].payload
            : ConstantsHelper.Chart.colorTransparent;
    let fill = ConstantsHelper.Chart.colorTransparent;
    let stroke = ConstantsHelper.Chart.colorTransparent;
    let height = 0;

    if (color !== ConstantsHelper.Chart.colorTransparent) {
        fill = color;
        stroke = ConstantsHelper.Chart.colorBlank;
        height = 16;
    }

    return (
        <path
            d={`M${params.x},${top} h${params.width} v${height} q0,${radius} -${radius},${radius} h${-(
                params.width -
                2 * radius
            )} q-${radius},0 -${radius},-${radius} v${-height} z`}
            fill={fill}
            stroke={stroke}
            strokeWidth={1.25}
        />
    );
};
const getCustomMarker = (params: IChartCallbackParams): JSX.Element => {
    const mark = insulinDeliveryDataAttributesGlobal.trackerMark[params.index];

    if (mark.type === ConstantsHelper.DataSubTypeTopOfHour) {
        const xOffset = -13 + (mark.payload?.length > 3 ? -4 : 0);

        return (
            <g key={`keyPod${mark.end}`} transform={`translate(${params.x},0)`}>
                <line x1={0} x2={0} y1={42} y2={50} stroke={'black'} strokeWidth={0.6} fill="1b1a1a" />
                <text
                    x={xOffset}
                    y="70"
                    fontFamily="OpenSans"
                    fontWeight="100"
                    fontSize="12px"
                    fill="1b1a1a"
                    stroke="tranparent"
                >
                    {mark.payload}
                </text>
            </g>
        );
    } else {
        return (
            <g
                key={`keyPod${mark.end}`}
                pointerEvents="all"
                id="podState"
                fill="black"
                stroke="white"
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round"
                fillOpacity={1.0}
                transform={`translate(${params.x},8)`}
            >
                <image height={24} xlinkHref={mark.payload} />
            </g>
        );
    }
};
const customRange = (params: IChartCallbackParams): JSX.Element =>
    ChartTooltipPopover(
        () => getCustomRange(params),
        () => chartTooltipPopoverBubble(params)
    );
const customMeasure = (params: IChartCallbackParams): JSX.Element =>
    ChartTooltipPopover(
        () => getCustomMeasure(params),
        () => chartTooltipPopoverBubble(params)
    );
const customMarker = (params: any): JSX.Element =>
    ChartTooltipPopover(
        () => getCustomMarker(params),
        () => chartTooltipPopoverBubble(params)
    );

const renderChartSub = (
    translate: ITranslator,
    showLegend: boolean,
    marginLeft: number,
    marginRight: number,
    chartData: IBulletChartData[]
) => (
    <>
        <AutoSizer style={{ width: '100%', height: '72px', display: 'flex' }}>
            {
                /* istanbul ignore next */
                () => (
                    <ResponsiveBullet
                        data={chartData}
                        margin={{
                            top: 0,
                            right: marginRight,
                            bottom: 40,
                            left: marginLeft,
                        }}
                        spacing={0}
                        titleOffsetX={0}
                        axisPosition="before"
                        markerComponent={customMarker}
                        measureComponent={customMeasure}
                        rangeComponent={customRange}
                        animate={false} // Keep this as false as when true sometimes the chart layout doesn't completely update
                        theme={{
                            tooltip: {
                                container: {
                                    background: '#333',
                                },
                            },
                        }}
                    />
                )
            }
        </AutoSizer>

        {showLegend &&
            ContributingEventChartLegend({
                insulinDeliveryDataAttributes: insulinDeliveryDataAttributesGlobal,
                translate: translate,
            })}

        <span id={legendMarkerEnd} className={styleGeneral.hideAlt}></span>
    </>
);

const renderChart = ({
    translate,
    app,
    showLegend,
    marginLeft,
    marginRight,
    haveData,
    chartData,
}: {
    translate: ITranslator;
    app: IAppState;
    showLegend: boolean;
    marginLeft: number;
    marginRight: number;
    haveData: IBulletPoint<string>;
    chartData: IBulletChartData[];
}) =>
    app?.loadingSemaphore === 0 &&
    (haveData ? (
        <div className={clsx(styleGeneral.chartBasalMode)}>
            {renderChartSub(translate, showLegend, marginLeft, marginRight, chartData)}
        </div>
    ) : (
        <div data-testid="chartNoData" className={styleGuide.noData}>
            {translate('nodata', { tag: translate('chart.mode') })}
        </div>
    ));

function ContributingEventChartInsulinDelivery({
    app,
    insulinDeliveryDataAttributes,
    marginLeft,
    marginRight,
}: {
    app: IAppState;
    insulinDeliveryDataAttributes: IChartPayload;
    marginLeft?: number;
    marginRight?: number;
}) {
    const dispatch = useDispatch();

    const showLegend = useSelector((state: RootState) => state.ui.showLegend);

    insulinDeliveryDataAttributesGlobal = insulinDeliveryDataAttributes;

    translateGlobal = (key: string, subs?: any) => UiHelper.Translate(app, key, subs);

    const legendTag = showLegend
        ? insulinDeliveryDataAttributesGlobal?.tag3
        : insulinDeliveryDataAttributesGlobal?.tag2;
    const haveTrackerRanges = insulinDeliveryDataAttributesGlobal?.trackerRange?.find(
        (trackerRangeEntry) => trackerRangeEntry.type
    );
    const haveTrackerMeasures = insulinDeliveryDataAttributesGlobal?.trackerMeasure?.find(
        (trackerMeasureEntry) => trackerMeasureEntry.type
    );
    const haveTrackerMarks = insulinDeliveryDataAttributesGlobal?.trackerMark?.find(
        (trackerMarkEntry) => trackerMarkEntry.type && trackerMarkEntry.type !== ConstantsHelper.DataSubTypeTopOfHour
    );
    const haveData =
        app?.currentReadingCombo.processedData && (haveTrackerRanges || haveTrackerMeasures || haveTrackerMarks);
    const chartData =
        insulinDeliveryDataAttributesGlobal?.data?.length > 0
            ? [
                  {
                      id: 'mode',
                      title: '',
                      ranges: insulinDeliveryDataAttributesGlobal?.trackerRange?.map((e) => e.end),
                      measures: insulinDeliveryDataAttributesGlobal?.trackerMeasure?.map((e) => e.end),
                      markers: insulinDeliveryDataAttributesGlobal?.trackerMark?.map((e) => e.end),
                  },
              ]
            : [];

    const handleViewLegendToggleClick = () => {
        const newShowLegend = !showLegend;

        dispatch(setShowLegend({ showLegend: newShowLegend }));

        setValuesFromStateInLocalStorage({ [ConstantsHelper.IntersessionKeys.showLegend]: newShowLegend });

        if (newShowLegend) {
            setTimeout(
                () =>
                    NavigationHelper.ScrollIntoView(legendMarkerEnd, () =>
                        setTimeout(() => NavigationHelper.ScrollIntoView(legendMarkerBeg), 1000)
                    ),
                500
            );
        }
    };

    return chartData.length === 0 ? null : (
        <div className={styleGuide.basalModeChart}>
            <span id={legendMarkerBeg} className={styleGeneral.hideAltTop}></span>

            <div className={styleGuide.title}>
                <div className={styleGuide.line1}>{insulinDeliveryDataAttributesGlobal?.tag}</div>

                {haveData && (
                    <div
                        data-testid="chartLegendFrame"
                        className={styleGuide.line2}
                        onClick={handleViewLegendToggleClick}
                    >
                        {legendTag}
                    </div>
                )}
            </div>

            {renderChart({
                translate: translateGlobal,
                app,
                showLegend,
                marginLeft,
                marginRight,
                haveData,
                chartData,
            })}
        </div>
    );
}

export default ContributingEventChartInsulinDelivery;
