import { IAppState } from '../../app.types';
import ConstantsHelper from '../../../../helpers/ConstantsHelper';
import UtilityHelper from '../../../../helpers/UtilityHelper';
import AnI18NextLibHelper from '../../../../helpers/AnI18NextLibHelper';

const updateProfileFulfilled = (state: IAppState, action: any) => {
    const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.updateProfile, [
        `${action.meta?.arg?.disclaimerSeen}`,
        `${action.meta?.arg?.welcomeSeen}`,
        `${action.meta?.arg?.eulaAccepted}`,
        `${action.meta?.arg?.confidentialityAgreementAccepted}`,
    ]);
    if (callKey) {
        delete state.activeHttpCalls[callKey];
    }
    state.patient = {
        ...state.patient,
        ...action.payload,
    };
    state.errors[callKey] = action.payload
        ? {
              level: ConstantsHelper.ErrorLevels.success,
              message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.success'),
              title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.pwdProfilePut'),
          }
        : {
              level: ConstantsHelper.ErrorLevels.error,
              message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.pwdProfileNotFound'),
              title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.pwdProfilePut'),
          };
    state.loadingSemaphore--;
};

export default updateProfileFulfilled;
