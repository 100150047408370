import React, { ReactChild } from 'react';

import styleModal from '../../styles/modal.module.scss';

interface IModalProps {
    children: ReactChild;
}

function Modal({ children }: IModalProps) {
    return (
        <div className={styleModal.modal}>
            <div>{children}</div>
        </div>
    );
}

export default Modal;
