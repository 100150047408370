import { ResponsiveBar } from '@nivo/bar';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import React from 'react';
import clsx from 'clsx';

import TrendLabel from './TrendLabel';
import BarLabel from './BarLabel';
import BarAxisBottomTick from './BarAxisBottomTick';

import { IInsulinWeeklyTrendData, ITranslator } from '../../types';
import {
    InsulinUsageTrendWeeklyTrend,
    TargetRelationalOperatorEqual,
    TargetRelationalOperatorGreaterThan,
    TargetRelationalOperatorGreaterThanOrEqual,
    TargetRelationalOperatorLessThan,
    TargetRelationalOperatorLessThanOrEqual,
    TargetRelationalOperatorNotEqual,
} from '../../model/models';
import { RootState } from '../../store/store';
import chartLegend from '../../assets/images/chart-legend-insulin-weekly-trends.png';
import ConstantsHelper from '../../helpers/ConstantsHelper';
import DateTimeHelper from '../../helpers/DateTimeHelper';
import styleGeneral from '../../styles/general.module.scss';
import styleGuide from '../../styles/styleGuide.module.scss';
import { IAppState } from '../../store/app/app.types';

const frameBeg = 110;

const responsiveBarProps = (
    goalsBolusRatio: number,
    goalsGlucoseInRange: number,
    data: IInsulinWeeklyTrendData[],
    translate: ITranslator,
    windowWidth: number
): any => {
    const markerHorizontalBase = {
        axis: 'y',
        legendOrientation: 'horizontal',
        legendOffsetX: windowWidth - 100,
        legendOffsetY: 0,
        lineStyle: {
            strokeWidth: 1,
        },
        textStyle: {
            fontFamily: 'OpenSans',
            fontSize: '12px',
            fontWeight: 'bold',
            start: 0,
        },
    };

    const dataCountFound = data.length;
    const dataCountExpected = 4;
    const dataCountStartIndex = dataCountExpected - dataCountFound;
    const dataEnhanced: IInsulinWeeklyTrendData[] = data?.map((d, index) => ({
        ...d,
        index: dataCountStartIndex + index,
    }));

    for (let i = 0; i < dataCountStartIndex; i++) {
        dataEnhanced.splice(0, 0, {
            index: i,
            frameBeg,
            bolus: 0,
            timeInRange: 0,
            frameEnd: 0,
        });
    }

    return {
        animate: true,
        areaOpacity: 0.15,
        axisBottom: {
            renderTick: ({ value, x, y }: { value: number; x: number; y: number }) => (
                <BarAxisBottomTick value={value} x={x} y={y} />
            ),
        },
        axisLeft: null,
        axisRight: null,
        axisTop: null,
        borderRadius: 8,
        colors: [
            ConstantsHelper.Chart.colorTransparent,
            ConstantsHelper.Chart.colorBolus,
            ConstantsHelper.Chart.colorTimeInRange,
            ConstantsHelper.Chart.colorTransparent,
        ],
        data: dataEnhanced,
        enableGridX: false,
        enableGridY: false,
        enableLabel: true,
        enablePointLabel: true,
        groupMode: 'grouped',
        height: 200,
        indexBy: 'index',
        innerPadding: 4,
        keys: ['frameBeg', 'bolus', 'timeInRange', 'frameEnd'],
        label: () => <></>,
        labelSkipHeight: 0,
        labelSkipWidth: 0,
        labelTextColor: 'inherit:darker(1.4)',
        layers: [
            'grid',
            'axes',
            'bars',
            'markers',
            'legends',
            'annotations',
            (props: any) => <BarLabel {...props} translate={translate} />,
        ],
        layout: 'vertical',
        margin: { top: 0, right: 0, bottom: 10, left: 36 },
        markers: [
            {
                ...markerHorizontalBase,
                legend: '100%',
                lineStyle: { ...markerHorizontalBase.lineStyle, stroke: 'silver' },
                textStyle: { ...markerHorizontalBase.textStyle, fill: 'black' },
                value: 100,
            },
            {
                ...markerHorizontalBase,
                legend: `${goalsGlucoseInRange}%`,
                lineStyle: { ...markerHorizontalBase.lineStyle, stroke: ConstantsHelper.Chart.colorTimeInRange },
                textStyle: { ...markerHorizontalBase.textStyle, fill: ConstantsHelper.Chart.colorTimeInRange },
                value: goalsGlucoseInRange,
            },
            {
                ...markerHorizontalBase,
                legend: `${goalsBolusRatio}%`,
                lineStyle: { ...markerHorizontalBase.lineStyle, stroke: ConstantsHelper.Chart.colorBolus },
                textStyle: { ...markerHorizontalBase.textStyle, fill: ConstantsHelper.Chart.colorBolus },
                value: goalsBolusRatio,
            },
            {
                ...markerHorizontalBase,
                legend: '0%',
                lineStyle: { ...markerHorizontalBase.lineStyle, stroke: 'silver' },
                textStyle: { ...markerHorizontalBase.textStyle, fill: 'black' },
                value: 0,
            },
        ],
        padding: 0,
        tooltip: () => <></>,
        width: windowWidth - 70,
        xScale: null,
        yScale: {
            type: 'linear',
            min: 0,
            max: frameBeg,
            stacked: false,
            reverse: false,
        },
    };
};

const getFirstValue = (
    dict:
        | TargetRelationalOperatorEqual
        | TargetRelationalOperatorGreaterThan
        | TargetRelationalOperatorGreaterThanOrEqual
        | TargetRelationalOperatorLessThan
        | TargetRelationalOperatorLessThanOrEqual
        | TargetRelationalOperatorNotEqual
): number => {
    const keys = dict ? Object.keys(dict) : [];

    if (keys?.length > 0) {
        return dict[keys[0]];
    }

    return null;
};

function WeeklyTrend({ app, translate }: { app: IAppState; translate: ITranslator }) {
    const windowSize = useSelector((state: RootState) => state.ui.windowSize);

    const weeklyTrend: InsulinUsageTrendWeeklyTrend = app?.currentInsulinUsage?.weeklyTrend ?? {
        goals: {},
        weeks: [],
    };
    const maximumWindowWidth = 390;
    const windowWidth = Math.min(windowSize?.width ?? maximumWindowWidth, maximumWindowWidth);
    const chartData: IInsulinWeeklyTrendData[] = weeklyTrend.weeks.map((s, idx) => ({
        bolus: s?.bolusStatistics?.ratioToInsulinTotal ?? 0,
        frameBeg,
        frameEnd: 0,
        index: idx,
        timeInRange: s?.glucoseInRange ?? 0,
    }));
    const goalsBolusRatio = getFirstValue(weeklyTrend.goals?.bolusRatio) ?? 0;
    const goalsGlucoseInRange = getFirstValue(weeklyTrend.goals?.glucoseInRange) ?? 0;

    if (chartData.length === 0) {
        return (
            <div className={styleGuide.noData}>
                {translate('nodata', { tag: translate('loyalty.insulin.weeklyTrend.title') })}
            </div>
        );
    }

    return (
        <div className={styleGuide.headerLoyalty}>
            <div className={styleGuide.loyaltyInsulinUsage}>
                <div className={clsx(styleGeneral.table, styleGuide.noGutter)}>
                    <img
                        src={chartLegend}
                        className={clsx(styleGuide.blockWidth)}
                        alt={translate('loyalty.insulin.weeklyTrend.legend')}
                    />
                </div>

                <div className={clsx(styleGeneral.table, styleGuide.loyaltyRow2)}>
                    <div className={styleGeneral.row}>
                        <span className={clsx(styleGeneral.cell, styleGuide.loyaltyBottomAlt)}>
                            <div className={styleGuide.loyaltyRow}>
                                <span className={styleGuide.loyaltyChart}>
                                    <ResponsiveBar
                                        {...responsiveBarProps(
                                            goalsBolusRatio,
                                            goalsGlucoseInRange,
                                            chartData,
                                            translate,
                                            windowWidth
                                        )}
                                    />
                                </span>
                            </div>
                        </span>
                    </div>

                    <div className={styleGeneral.row}>
                        <span className={clsx(styleGeneral.cell, styleGuide.loyaltyBottomAlt)}>
                            <div className={styleGeneral.table}>
                                <div className={clsx(styleGeneral.row, styleGuide.loyaltyRow)}>
                                    <span
                                        className={clsx(
                                            styleGeneral.cell,
                                            styleGuide.loyaltyCell,
                                            styleGuide.loyaltyColLeft
                                        )}
                                    >
                                        <span className={styleGuide.loyaltyLabelLeft}>
                                            {translate('loyalty.insulin.weeklyTrend.weekOf')}:
                                        </span>
                                    </span>
                                    {weeklyTrend.weeks.map((week, index) => (
                                        <span
                                            key={`weeklyTrend${uuidv4()}`}
                                            className={clsx(
                                                styleGeneral.cell,
                                                styleGuide.loyaltyCell,
                                                styleGuide.loyaltyColOther
                                            )}
                                        >
                                            <span className={styleGuide.loyaltyLabelOther}>
                                                {DateTimeHelper.FormatDateGlucoseTrends(
                                                    app?.dashboardBeg,
                                                    index - weeklyTrend.weeks.length + 1
                                                )}
                                            </span>
                                        </span>
                                    ))}
                                </div>
                                <div className={clsx(styleGeneral.row, styleGuide.loyaltyRow)}>
                                    <span
                                        className={clsx(
                                            styleGeneral.cell,
                                            styleGuide.loyaltyCell,
                                            styleGuide.loyaltyColLeft
                                        )}
                                    >
                                        <span className={styleGuide.loyaltyLabelLeft}>
                                            {translate('loyalty.insulin.weeklyTrend.avgNumBolusesPerDay')}:
                                        </span>
                                    </span>
                                    {weeklyTrend.weeks.map((week) => (
                                        <span
                                            key={`weeklyTrend${uuidv4()}`}
                                            className={clsx(
                                                styleGeneral.cell,
                                                styleGuide.loyaltyCell,
                                                styleGuide.loyaltyColOther
                                            )}
                                        >
                                            <TrendLabel
                                                showUnits={false}
                                                value={week?.bolusStatistics?.avgNumber}
                                                translate={translate}
                                            />
                                        </span>
                                    ))}
                                </div>
                                <div className={clsx(styleGeneral.row, styleGuide.loyaltyRow)}>
                                    <span
                                        className={clsx(
                                            styleGeneral.cell,
                                            styleGuide.loyaltyCell,
                                            styleGuide.loyaltyColLeft
                                        )}
                                    >
                                        <span className={styleGuide.loyaltyLabelLeft}>
                                            {translate('loyalty.insulin.weeklyTrend.avgTotalDailyInsulin')}:
                                        </span>
                                    </span>
                                    {weeklyTrend.weeks.map((week) => (
                                        <span
                                            key={`weeklyTrend${uuidv4()}`}
                                            className={clsx(
                                                styleGeneral.cell,
                                                styleGuide.loyaltyCell,
                                                styleGuide.loyaltyColOther
                                            )}
                                        >
                                            <TrendLabel showUnits value={week?.avgInsulinTotal} translate={translate} />
                                        </span>
                                    ))}
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WeeklyTrend;
