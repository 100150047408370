import { useTranslation } from 'react-i18next';
import React from 'react';
import clsx from 'clsx';

import LoyaltyCongrats from './loyaltyCongrats';
import LoyaltyInsulinModeUsage from './LoyaltyInsulinModeUsage';

import { IAppState } from '../../../store/app/app.types';
import styleGeneral from '../../../styles/general.module.scss';
import styleGuide from '../../../styles/styleGuide.module.scss';

function LoyaltyFrame({
    app,
    scrollActive2,
    cbLoyaltyCongrats,
    cbLoyaltyInsulin,
}: {
    app: IAppState;
    scrollActive2: any;
    cbLoyaltyCongrats?: any;
    cbLoyaltyInsulin?: any;
}) {
    const { t } = useTranslation();

    // Skip both cards if there's no content to show
    return !(
        app?.currentLearningMaterialProgress?.data?.weekNumber || app?.currentInsulinUsage?.dailyUsage?.insulinRatio
    ) ? null : (
        <div className={styleGuide.insights} key="patternLoyaltyOverall">
            <div className={clsx(styleGuide.solidBg, styleGuide.header)}>
                <div className={clsx(styleGuide.line)}></div>
                <div
                    className={clsx(
                        styleGuide.title,
                        styleGeneral.toCapitalize,
                        scrollActive2 && styleGeneral.inScroll2
                    )}
                >
                    {t('loyalty.headerA')}
                    <sup>&#174;</sup>&nbsp;{t('loyalty.headerB')}
                </div>
            </div>
            {!app.patient?.isOnboarded && <LoyaltyCongrats app={app} cbLoyaltyCongrats={cbLoyaltyCongrats} />}
            <LoyaltyInsulinModeUsage app={app} cbLoyaltyInsulin={cbLoyaltyInsulin} />
        </div>
    );
}

export default LoyaltyFrame;
