import { createSlice } from '@reduxjs/toolkit';

import { fetchProfile } from './profile.thunks';
import { IProfileState } from './profile.types';
import fetchProfileFulfilled from './extraReducers/fulfilled.extraReducer';

import { resetStore } from '../root.actions';

const initialState: IProfileState = {
    confidentialityAgreementAccepted: false,
    dateOfBirth: null,
    disclaimerSeen: false,
    eulaAccepted: false,
    firstJoined: null,
    firstName: null,
    id: null,
    isEnrolled: false,
    isOnboarded: false,
    isUnenrolled: false,
    lastName: null,
    physicianName: null,
    receivedData: false,
    reportDaysAvailable: null,
    reportDaysAvailableString: null,
    userType: null,
    welcomeSeen: false,
};

const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(resetStore, () => initialState).addCase(fetchProfile.fulfilled, fetchProfileFulfilled);
    },
});

export default profileSlice.reducer;
