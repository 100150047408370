import { Switch, useHistory, Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { SecureRoute, Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import clsx from 'clsx';
import Spinner from 'react-bootstrap/Spinner';

import ErrorHandler from 'src/components/app/ErrorHandler';
import Agreements from 'src/components/app/Agreements';
import LoginCallback from 'src/pages/LoginCallback';
import Login from 'src/pages/Login';
import EnrollmentRequiredRoute from 'src/components/app/EnrollmentRequiredRoute';

import TelemetryProvider from './appInsightsTelemetryProvider';

import { sendAzureEvent } from '../helpers/appInsights';
import { endpointUrlPrefixActionCreator, anI18NextLibActionCreator } from '../store/app/app.slice';
import { getValuesFromLocalStorage } from '../helpers/SessionHelper';
import { ILocalStorageValues } from '../store/root.types';
import { restoreStateFromLocalStorage } from '../store/root.actions';
import { setWindowSize } from '../store/ui/ui.slice';
import { RootState } from '../store/store';
import { appInitialState } from '../store/app/app.initialState';
import { fetchProfile } from '../store/app/app.thunks';
import Header from '../components/app/Header';
import AnI18NextLibHelper from '../helpers/AnI18NextLibHelper';
import ConstantsHelper from '../helpers/ConstantsHelper';
import MenuHelper from '../helpers/MenuHelper';
import SystemHelper from '../helpers/SystemHelper';
import UiHelper from '../helpers/UiHelper';
import styleGeneral from '../styles/general.module.scss';
import styleGuide from '../styles/styleGuide.module.scss';
import * as pages from '../pages';
import 'bootstrap/dist/css/bootstrap.css'; // Import precompiled Bootstrap css
import './App.scss';

let appInsights: any = null;

const aiProps = {
    after: (aiObject: any) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        appInsights = aiObject;
    },
};

const bemBlockName = 'app';

function App() {
    const history = useHistory();
    const dispatch = useDispatch();

    const appDefault = useSelector((state: RootState) => state.app);
    const oktaData = useSelector((state: RootState) => state.authentication.oktaData);
    const isMenuOpen = useSelector((state: RootState) => state.ui.isMenuOpen);

    const app = SystemHelper.GetCleanState(appDefault, appInitialState);

    const { oktaAuth, endpointPwdUrlPrefix, hcpAppUrl, endpointPdfUrlPrefix, loginURL } = MenuHelper.Init();
    const isProduction = SystemHelper.IsProd();
    const browserInfo = SystemHelper.GetBrowserInfo();
    const restoreOriginalUri = async (_oktaAuth: any, originalUri: string) =>
        history.replace(toRelativeUrl(originalUri || '/', window.location.origin));

    const [readyToGo, setReadyToGo] = useState(UiHelper.IsSiteReadyToGo(app));

    const isPauseItEnabled = SystemHelper.IsPauseItEnabled();

    useEffect(() => {
        let pollingTimer: ReturnType<typeof setInterval>;
        if (app.isPolling) {
            pollingTimer = setInterval(() => {
                dispatch(fetchProfile(null));
            }, 3000);
        }

        return () => {
            if (pollingTimer) {
                clearInterval(pollingTimer);
            }
        };
    }, [app.isPolling, dispatch]);

    useEffect(() => {
        const valuesFromLocalStorage: ILocalStorageValues = getValuesFromLocalStorage();

        dispatch(restoreStateFromLocalStorage(valuesFromLocalStorage));
        dispatch(anI18NextLibActionCreator({ anI18Nextlib: AnI18NextLibHelper.loadLang('en') }));
        dispatch(endpointUrlPrefixActionCreator({ endpointPwdUrlPrefix, hcpAppUrl, endpointPdfUrlPrefix }));

        MenuHelper.MapMenuComponents(app, pages);
        SystemHelper.AppInsightsEvent(
            app,
            'App',
            `Mounted -- environment:  ${isProduction ? 'Prod' : 'Non-prod'} -- browser:  ${browserInfo.tag} is ${
                browserInfo.supported ? 'supported' : 'unsupportred'
            }`
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useLayoutEffect(() => {
        const updateWindowSize = () => {
            dispatch(
                setWindowSize({
                    height: window.innerHeight,
                    width: window.innerWidth,
                })
            );
        };

        window.addEventListener('resize', updateWindowSize);
        updateWindowSize();
        return () => window.removeEventListener('resize', updateWindowSize);
    }, [dispatch]);

    useEffect(() => {
        const newReadyToGo = UiHelper.IsSiteReadyToGo(app);

        if (!readyToGo && newReadyToGo) {
            MenuHelper.MapMenuComponents(app, pages);
            setReadyToGo(true);
        }
    }, [readyToGo, app]);

    let onAuthRequired;

    if (isPauseItEnabled) {
        onAuthRequired = (_oktaAuth: OktaAuth) => {
            sendAzureEvent(ConstantsHelper.analyticsEventNames.REDIRECT_TO_LOGIN, {
                redirectPage: _oktaAuth?.options.issuer || '/',
            });
            _oktaAuth?.signInWithRedirect({ originalUri: '/' });
        };
    } else {
        onAuthRequired = () => {
            if (oktaData.isUserDefined) {
                sendAzureEvent(ConstantsHelper.analyticsEventNames.LOG_OUT);
            }

            MenuHelper.redirectToLogin(loginURL);
        };
    }

    const totalCallsToConsiderFetching = app.isPolling ? 2 : 1;
    const isFetching = app.loadingSemaphore >= totalCallsToConsiderFetching;
    const shouldDisableContent = isFetching || isMenuOpen;

    const redirectToHcp = () => {
        const hcpAppUrl = SystemHelper?.getConfig(
            ConstantsHelper.urlParamsConstants.hcpAppUrl.urlKey,
            ConstantsHelper.urlParamsConstants.hcpAppUrl.settingsKey,
            ConstantsHelper.urlParamsConstants.hcpAppUrl.runtimeKey,
            false,
            true
        );
        window.location.replace(hcpAppUrl);
    };

    if (SystemHelper.IsHcpUserSession(oktaAuth, isPauseItEnabled)) {
        redirectToHcp();
    }

    return (
        <TelemetryProvider {...aiProps}>
            <div className={clsx(styleGeneral.main, styleGuide.dashboard)}>
                {readyToGo || (
                    <Spinner className={styleGeneral.spinner} animation="border" role="status" data-testid="spinner" />
                )}

                {readyToGo && (
                    <>
                        <div
                            className={clsx({
                                [styleGeneral.show]: shouldDisableContent,
                                [styleGeneral.layerTopmost]: shouldDisableContent,
                                [styleGeneral.hide]: !shouldDisableContent,
                            })}
                            data-testid={`${bemBlockName}__overlay`}
                        />

                        {isFetching && (
                            <Spinner
                                className={styleGeneral.spinner}
                                data-testid={`${bemBlockName}__fetching-after-initial-fetch-spinner`}
                                animation="border"
                                role="status"
                            />
                        )}
                    </>
                )}

                <div className="App">
                    {app.allLoaded && (
                        <Security
                            oktaAuth={oktaAuth}
                            restoreOriginalUri={restoreOriginalUri}
                            onAuthRequired={onAuthRequired}
                        >
                            {readyToGo && (
                                <div className={styleGeneral.header}>
                                    <Header app={app} />
                                </div>
                            )}
                            <Switch>
                                <Route path="/insuletid/callback" component={Login} />
                                <Route path="/login/callback" component={LoginCallback} />
                                <Route path="/error" component={pages.Error} />
                                <SecureRoute path="/">
                                    <ErrorHandler>
                                        <Agreements>
                                            {MenuHelper.MenuEntries.filter((e) => e.path).map((e: any) => {
                                                // TODO: Only render routes enabled for patient's device class.
                                                // That will require refactoring menu entry handling, menu entries should not be static.
                                                return <EnrollmentRequiredRoute key={e.path} {...e} />;
                                            })}
                                        </Agreements>
                                    </ErrorHandler>
                                </SecureRoute>
                                <Redirect to="/error" />
                            </Switch>
                        </Security>
                    )}
                </div>
            </div>
        </TelemetryProvider>
    );
}

export default App;
