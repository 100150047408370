import React, { useEffect, ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import UiHelper from 'src/helpers/UiHelper';

import { RootState } from '../../store/store';
import { IError } from '../../store/app/app.types';
import { IObjectHash } from '../../types';
import { setIsMenuVisible } from '../../store/ui/ui.slice';
import SystemHelper from '../../helpers/SystemHelper';
import NotFound from '../common/contentWrappers/NotFound';

function ErrorHandler({ children }: { children?: ReactNode }): JSX.Element {
    const app = useSelector((state: RootState) => state.app);

    const dispatch = useDispatch();
    const error: IObjectHash<IError> = app.errors ?? ({} as IObjectHash<IError>);
    const showError = UiHelper.HaveError(app) && !error.silent;

    const clearErrorCb = () => SystemHelper.DismissError(dispatch);

    useEffect(() => {
        dispatch(setIsMenuVisible({ isMenuVisible: !showError }));
    }, [dispatch, showError]);

    if (showError) {
        return <NotFound clearErrorCb={clearErrorCb} />;
    }

    return <>{children}</>;
}

export default ErrorHandler;
