import UtilityHelper from 'src/helpers/UtilityHelper';

import { IProfileState } from '../profile.types';

const fetchProfileFulfilled = (state: IProfileState, action: any) => {
    const newState = {
        ...action.payload,
        firstJoined: UtilityHelper.CryptoEncrypt(action.payload.firstJoined),
        firstName: UtilityHelper.CryptoEncrypt(action.payload.firstName),
        id: UtilityHelper.CryptoEncrypt(action.payload.id),
        lastName: UtilityHelper.CryptoEncrypt(action.payload.lastName),
        reportDaysAvailableString: UtilityHelper.CryptoEncrypt(`${action.payload.reportDaysAvailable}`),
        reportDaysAvailable: -999,
    };

    return newState;
};

export default fetchProfileFulfilled;
