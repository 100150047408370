import { useTranslation } from 'react-i18next';
import { Paper } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import InsightSummaryDaysInRange from './InsightSummaryDaysInRange';
import InsightSummaryTimeInRange from './InsightSummaryTimesInRange';
import InsightSummaryGlucoseTrends from './InsightSummaryGlucoseTrends';

import { IInsight3 } from '../../../types';
import { Insight3 } from '../../../model/insight3';
import { Insight3GlucoseTrend } from '../../../model/models';
import { RootState } from '../../../store/store';
import { IAppState } from '../../../store/app/app.types';
import styleGuide from '../../../styles/styleGuide.module.scss';
import styleGeneral from '../../../styles/general.module.scss';
import DateTimeHelper from '../../../helpers/DateTimeHelper';
import SystemHelper from '../../../helpers/SystemHelper';
import UtilityHelper from '../../../helpers/UtilityHelper';

function InsightSummaryCarousel({
    app,
    scrollActive2,
    summary,
    summaryGlucoseTrend,
    setIndex,
}: {
    app: IAppState;
    scrollActive2: boolean;
    summary: Insight3;
    summaryGlucoseTrend: Insight3GlucoseTrend;
    setIndex: any;
}) {
    const { t } = useTranslation();
    const carouselIndex = useSelector((state: RootState) => state.ui.carouselIndex);

    useEffect(() => {
        SystemHelper.AppInsightsInfo(app, 'Dashboard', 'Debug', ' Summary');
    }, [app]);

    const summaryFinal: IInsight3 = {
        ...summary,
        activePct: 89,
        activePctDelta: -3,
        avgUnitsPerDay: 17,
        avgUnitsPerDayBasal: 6,
        avgUnitsPerDayBolus: 14,
        avgUnitsPerDayDelta: 11,
        timeInModeAutoPct: 64,
        timeInModeAutoPctDelta: 3,
        timeInRangePctDelta: summaryGlucoseTrend?.previousWeekComparison?.differenceInRangeValue,
        timeInModeLimitedPct: 3,
        timeInModeManualPct: 33,
        timeInRangePct: summaryGlucoseTrend?.currentWeekGlucoseTrend?.inRange,
        timeInRangeHighPct: summaryGlucoseTrend?.currentWeekGlucoseTrend?.high,
        timeInRangeLowPct: summaryGlucoseTrend?.currentWeekGlucoseTrend?.low,
        prevWeekLabel: DateTimeHelper.FormatDateRange(
            DateTimeHelper.GetIsoWeekRange(app.dashboardEnd, -7)?.end,
            undefined,
            true
        ),
    };

    const items = [
        {
            name: t('daysInRange'),
            component: InsightSummaryDaysInRange,
            show: summary?.celebrationDays?.filter((d) => d.percentage >= summary.goodPctThreshold).length > 0,
        },
        {
            name: t('timeInRange'),
            component: InsightSummaryTimeInRange,
            show:
                summaryGlucoseTrend?.currentWeekGlucoseTrend &&
                (!UtilityHelper.IsNull(summaryGlucoseTrend?.currentWeekGlucoseTrend.inRange) ||
                    !UtilityHelper.IsNull(summaryGlucoseTrend?.currentWeekGlucoseTrend.high) ||
                    !UtilityHelper.IsNull(summaryGlucoseTrend?.currentWeekGlucoseTrend.low)),
        },
        {
            name: t('glucoseTrends'),
            component: InsightSummaryGlucoseTrends,
            show:
                summaryGlucoseTrend?.currentWeekGlucoseTrend &&
                !UtilityHelper.IsNull(summaryGlucoseTrend?.previousWeekComparison?.differenceInRangeValue),
        },
    ];

    const itemsToShow = items.filter((e) => e.show);
    const showNavCtls = itemsToShow.length > 1;
    const showItems = itemsToShow.length > 0;

    return !showItems ? null : (
        <div className={clsx(styleGuide.insights, styleGeneral.dashboardSummary)} key="patternSummaryOverall">
            <div className={clsx(styleGuide.solidBg, styleGuide.header)}>
                <div
                    className={clsx(
                        styleGuide.title,
                        styleGeneral.toCapitalize,
                        scrollActive2 && styleGeneral.inScroll2
                    )}
                >
                    {t('summary')}
                </div>
            </div>

            <div className={styleGuide.InsightCard} data-testid={'carouselTestId'}>
                <Carousel
                    autoPlay={false}
                    animation={'slide'}
                    className={styleGuide.carousel}
                    index={carouselIndex}
                    indicators={showNavCtls}
                    reverseEdgeAnimationDirection={false}
                    cycleNavigation={false}
                    navButtonsAlwaysVisible={showNavCtls}
                    navButtonsProps={{
                        className: styleGuide.navButtons,
                    }}
                    activeIndicatorIconButtonProps={{
                        className: styleGuide.carouselIndicator,
                    }}
                    onChange={(evt: any) => setIndex({ index: evt })}
                >
                    {itemsToShow.map((item, idx) => (
                        <Paper key={`slide${idx}`} className={styleGuide.item}>
                            {item.component({ app, summary: summaryFinal })}
                        </Paper>
                    ))}
                </Carousel>
            </div>
        </div>
    );
}

export default InsightSummaryCarousel;
