import { OktaAuth } from '@okta/okta-auth-js';

import { Patient } from 'src/model/patient';

import { sendAzureEvent } from './appInsights';
import SystemHelper from './SystemHelper';
import ConstantsHelper from './ConstantsHelper';
import UtilityHelper from './UtilityHelper';

import { IMenuEntry } from '../types';
import homeIcon from '../assets/images/icon-home.png';
import aboutIcon from '../assets/images/icon-about.png';
import downloadIcon from '../assets/images/icon-download.svg';
import settingsIcon from '../assets/images/icon-settings.svg';
import helpIcon from '../assets/images/icon-help.png';
import signInIcon from '../assets/images/icon-signIn.png';
import signOutIcon from '../assets/images/icon-signOut.png';
import allReportsIcon from '../assets/images/icon-all-reports.png';
import { IAppState } from '../store/app/app.types';

export default class MenuHelper {
    public static readonly MenuEntryHomeKey = 'home';
    public static readonly MenuEntryAllReportsKey = 'allReports';
    public static readonly MenuEntryAboutKey = 'about';
    public static readonly MenuEntryDownloadKey = 'download';
    public static readonly MenuEntrySettingsKey = 'settings';
    public static readonly MenuEntryHelpKey = 'help';
    public static readonly MenuEntrySignOutKey = 'signOut';
    public static readonly MenuEntrySignInKey = 'signIn';
    public static readonly MenuEntryEventKey = 'event';
    public static readonly MenuEntryInsightKey = 'insight';
    public static readonly MenuEntryCongratsInsightsKey = 'congratsInsights';
    public static readonly MenuEntryInsulinUsageInsightsKey = 'insulinUsageInsights';
    public static readonly MenuEntryDashboardKey = 'dashboard';

    private static readonly transKeyHome = 'menu.home';
    private static readonly transKeyDashboard = 'menu.dashboard';
    private static readonly transKeyAllReports = 'menu.allReports';
    private static readonly transKeyAbout = 'menu.about';
    private static readonly transKeyDownload = 'menu.download';
    private static readonly transKeySettings = 'menu.settings';
    private static readonly transKeyHelp = 'menu.help';
    private static readonly transKeySignOut = 'menu.signOut';
    private static readonly transKeySignIn = 'menu.signIn';
    private static readonly transEventDetails = 'menu.eventDetails';
    private static readonly transInsightDetails = 'menu.insightDetails';
    private static readonly transCongratsInsights = 'menu.congratsInsights';
    private static readonly transInsulinUsageInsights = 'menu.insulinUsageInsights';

    private static readonly pathHome = '/';
    private static readonly pathDashboard = '/week/:dashboardBeg/:dashboardEnd/:dashboardHasReferrer';
    private static readonly pathAllReports = '/all-reports';
    private static readonly pathAbout = '/about';
    private static readonly pathDownload = '/download';
    private static readonly pathSettings = '/settings';
    private static readonly pathHelp = '/help';
    private static readonly pathEvent =
        '/event/:dashboardBeg/:dashboardEnd/:dashboardHasReferrer/:insightId/:contributingEventId/:contributingEventBeg/:contributingEventEnd';
    private static readonly pathInsight = '/insight/:dashboardBeg/:dashboardEnd/:dashboardHasReferrer/:insightId';
    private static readonly pathCongratsInsights = '/congrats/:dashboardBeg/:dashboardEnd/:dashboardHasReferrer';
    private static readonly pathInsulinUsageInsights = '/insulin/:dashboardBeg/:dashboardEnd/:dashboardHasReferrer';
    private static readonly pathRedirectToOkta = '/insuletid/callback';
    private static readonly pathOktaIn = '/login/callback';

    public static readonly MenuEntries: IMenuEntry[] = [
        {
            key: MenuHelper.MenuEntryHomeKey,
            label: MenuHelper.transKeyHome,
            path: MenuHelper.pathHome,
            exact: true,
            icon: homeIcon,
        },
        {
            key: MenuHelper.MenuEntryDashboardKey,
            label: MenuHelper.transKeyDashboard,
            path: MenuHelper.pathDashboard,
            hidden: true,
        },
        {
            key: MenuHelper.MenuEntryAllReportsKey,
            label: MenuHelper.transKeyAllReports,
            path: MenuHelper.pathAllReports,
            exact: true,
            icon: allReportsIcon,
            preSeparate: true,
        },
        {
            key: MenuHelper.MenuEntryDownloadKey,
            label: MenuHelper.transKeyDownload,
            path: MenuHelper.pathDownload,
            exact: true,
            icon: downloadIcon,
            preSeparate: true,
        },
        {
            key: MenuHelper.MenuEntrySettingsKey,
            label: MenuHelper.transKeySettings,
            path: MenuHelper.pathSettings,
            exact: true,
            icon: settingsIcon,
            preSeparate: true,
        },
        {
            key: MenuHelper.MenuEntryAboutKey,
            label: MenuHelper.transKeyAbout,
            path: MenuHelper.pathAbout,
            exact: true,
            icon: aboutIcon,
            preSeparate: true,
        },
        {
            key: MenuHelper.MenuEntryHelpKey,
            label: MenuHelper.transKeyHelp,
            path: MenuHelper.pathHelp,
            exact: true,
            icon: helpIcon,
            preSeparate: true,
        },
        {
            key: MenuHelper.MenuEntrySignOutKey,
            label: MenuHelper.transKeySignOut,
            exact: true,
            preSeparate: true,
            icon: signOutIcon,
        },
        {
            key: MenuHelper.MenuEntrySignInKey,
            label: MenuHelper.transKeySignIn,
            exact: true,
            hidden: true,
            icon: signInIcon,
        },
        {
            key: MenuHelper.MenuEntryEventKey,
            label: MenuHelper.transEventDetails,
            path: MenuHelper.pathEvent,
            hidden: true,
        },
        {
            key: MenuHelper.MenuEntryInsightKey,
            label: MenuHelper.transInsightDetails,
            path: MenuHelper.pathInsight,
            hidden: true,
        },
        {
            key: MenuHelper.MenuEntryCongratsInsightsKey,
            label: MenuHelper.transCongratsInsights,
            path: MenuHelper.pathCongratsInsights,
            hidden: true,
        },
        {
            key: MenuHelper.MenuEntryInsulinUsageInsightsKey,
            label: MenuHelper.transInsulinUsageInsights,
            path: MenuHelper.pathInsulinUsageInsights,
            hidden: true,
        },
    ];

    public static readonly MenuEntryHome = MenuHelper.MenuEntries.find((e) => e.key === MenuHelper.MenuEntryHomeKey);
    public static readonly MenuEntrySignOut = MenuHelper.MenuEntries.find(
        (e) => e.key === MenuHelper.MenuEntrySignOutKey
    );
    public static readonly MenuEntrySignIn = MenuHelper.MenuEntries.find(
        (e) => e.key === MenuHelper.MenuEntrySignInKey
    );

    public static MapMenuComponents = (app: IAppState, pages: any) => {
        const enabledEntriesForAnyDeviceClass = [
            MenuHelper.MenuEntryHomeKey,
            MenuHelper.MenuEntrySettingsKey,
            MenuHelper.MenuEntryAboutKey,
            MenuHelper.MenuEntryHelpKey,
            MenuHelper.MenuEntrySignOutKey,
            MenuHelper.MenuEntrySignInKey,
        ];

        const enabledEntriesForKnownDeviceClass = [MenuHelper.MenuEntryDownloadKey];
        const deviceClass = app.patient?.deviceClass;

        // WARNING:  Backfill menu entries with their associated components on startup since specifying them in MenuHelper could cause issues
        //           with components accessing Redux store prematurely
        MenuHelper.MenuEntries.forEach((m) => {
            switch (m.key) {
                case MenuHelper.MenuEntryHomeKey:
                    switch (deviceClass) {
                        case Patient.DeviceClassEnum.Omnipod5:
                            m.component = pages.Dashboard;
                            break;
                        case Patient.DeviceClassEnum.Dash:
                        case Patient.DeviceClassEnum.Eros:
                            m.component = pages.Download;
                            break;
                        case undefined:
                            m.component = null;
                            break;
                        default:
                            m.component = pages.Error;
                    }
                    break;
                case MenuHelper.MenuEntryDashboardKey:
                    m.component = pages.Dashboard;
                    break;
                case MenuHelper.MenuEntryDownloadKey:
                    m.component = pages.Download;
                    break;
                case MenuHelper.MenuEntrySettingsKey:
                    m.component = pages.Settings;
                    break;
                case MenuHelper.MenuEntryAllReportsKey:
                    m.component = pages.AllReports;
                    break;
                case MenuHelper.MenuEntryAboutKey:
                    m.component = pages.About;
                    break;
                case MenuHelper.MenuEntryHelpKey:
                    m.component = pages.Help;
                    break;
                case MenuHelper.MenuEntryEventKey:
                    m.component = pages.ContributingEvent;
                    break;
                case MenuHelper.MenuEntryInsightKey:
                    m.component = pages.Insight;
                    break;
                case MenuHelper.MenuEntryCongratsInsightsKey:
                    m.component = pages.LoyaltyCongratsInsights;
                    break;
                case MenuHelper.MenuEntryInsulinUsageInsightsKey:
                    m.component = pages.LoyaltyInsulinUsageInsights;
                    break;
            }

            // TODO: Add properties to IMenuEntry that allow to configure if menu item is disabled/hidden for device class.
            if (deviceClass === Patient.DeviceClassEnum.Omnipod5) {
                m.disabled = false;
            } else if (deviceClass === Patient.DeviceClassEnum.Dash || deviceClass === Patient.DeviceClassEnum.Eros) {
                m.disabled = !(
                    enabledEntriesForAnyDeviceClass.includes(m.key) || enabledEntriesForKnownDeviceClass.includes(m.key)
                );
            } else {
                m.disabled = !enabledEntriesForAnyDeviceClass.includes(m.key);
            }

            if (m.key === MenuHelper.MenuEntryHomeKey) {
                m.hidden = deviceClass !== Patient.DeviceClassEnum.Omnipod5;
            }
        });
    };

    public static Init = () => {
        const endpointPwdUrlPrefix = SystemHelper?.getConfig(
            ConstantsHelper.urlParamsConstants.endpointPwdUrlPrefix.urlKey,
            ConstantsHelper.urlParamsConstants.endpointPwdUrlPrefix.settingsKey,
            ConstantsHelper.urlParamsConstants.endpointPwdUrlPrefix.runtimeKey,
            true,
            true
        );
        const hcpAppUrl = SystemHelper?.getConfig(
            ConstantsHelper.urlParamsConstants.hcpAppUrl.urlKey,
            ConstantsHelper.urlParamsConstants.hcpAppUrl.settingsKey,
            ConstantsHelper.urlParamsConstants.hcpAppUrl.runtimeKey,
            false,
            true
        );
        const endpointPdfUrlPrefix = SystemHelper?.getConfig(
            ConstantsHelper.urlParamsConstants.endpointPdfUrlPrefix.urlKey,
            ConstantsHelper.urlParamsConstants.endpointPdfUrlPrefix.settingsKey,
            ConstantsHelper.urlParamsConstants.endpointPdfUrlPrefix.runtimeKey,
            false,
            true
        );
        const oktaIssuer = SystemHelper?.getConfig(
            ConstantsHelper.urlParamsConstants.oktaIssuer.urlKey,
            ConstantsHelper.urlParamsConstants.oktaIssuer.settingsKey,
            ConstantsHelper.urlParamsConstants.oktaIssuer.runtimeKey
        );
        const oktaClientID = SystemHelper?.getConfig(
            ConstantsHelper.urlParamsConstants.oktaClientID.urlKey,
            ConstantsHelper.urlParamsConstants.oktaClientID.settingsKey,
            ConstantsHelper.urlParamsConstants.oktaClientID.runtimeKey
        );

        const InsuletIDURL = SystemHelper?.getConfig(
            ConstantsHelper.urlParamsConstants.insuletIDURL.urlKey,
            ConstantsHelper.urlParamsConstants.insuletIDURL.settingsKey,
            ConstantsHelper.urlParamsConstants.insuletIDURL.runtimeKey
        );

        const insuletOktaApp = SystemHelper?.getConfig(
            ConstantsHelper.urlParamsConstants.insuletOktaApp.urlKey,
            ConstantsHelper.urlParamsConstants.insuletOktaApp.settingsKey,
            ConstantsHelper.urlParamsConstants.insuletOktaApp.runtimeKey
        );

        const redirectURI = window.location.origin + MenuHelper.pathOktaIn;

        const oktaAuth = new OktaAuth({
            clientId: oktaClientID ?? 'dummy_value_used_for_testing',
            issuer: oktaIssuer ?? 'https://insulet.com/oauth2/dummy_value_for_testing',
            redirectUri: redirectURI,
            ...(SystemHelper.IsPauseItEnabled()
                ? {
                      pkce: true,
                      scopes: ['openid', 'profile', 'email'],
                  }
                : {
                      pkce: false,
                      tokenManager: {
                          autoRenew: false,
                      },
                  }),
        });

        const returnURL = `${window.location.origin}${MenuHelper.pathRedirectToOkta}`;
        const loginURL = UtilityHelper.generateLoginLink(InsuletIDURL, insuletOktaApp, returnURL);
        const original_signInWithRedirect = oktaAuth.signInWithRedirect;

        oktaAuth.signInWithRedirect = (opts) => original_signInWithRedirect.apply(oktaAuth, opts);

        return {
            oktaAuth,
            endpointPwdUrlPrefix,
            hcpAppUrl,
            endpointPdfUrlPrefix,
            loginURL,
        };
    };

    public static getLogoutUrl = () => window.location.origin + '/';

    public static redirectToSettings = () => {
        window.location.assign(MenuHelper.pathSettings);
    };

    public static redirectToLogin = (loginURL: string) => {
        sendAzureEvent(ConstantsHelper.analyticsEventNames.REDIRECT_TO_LOGIN, {
            redirectPage: loginURL,
        });

        window.location.assign(loginURL);
    };
}
