import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from '@mui/material';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import clsx from 'clsx';

import { dashboardMetaDataActionCreator } from '../store/app/app.slice';
import { sendAzureEvent } from '../helpers/appInsights';
import { RootState } from '../store/store';
import { setAnchorEvent } from '../store/ui/ui.slice';
import { appInitialState } from '../store/app/app.initialState';
import SystemHelper from '../helpers/SystemHelper';
import styleGeneral from '../styles/general.module.scss';
import styleGuide from '../styles/styleGuide.module.scss';
import BtnBack from '../components/common/btnBack';
import ConstantsHelper from '../helpers/ConstantsHelper';
import NavigationHelper from '../helpers/NavigationHelper';
import UiHelper from '../helpers/UiHelper';
import DateTimeHelper from '../helpers/DateTimeHelper';
import UtilityHelper from '../helpers/UtilityHelper';
import BtnPrimary from '../components/common/btnPrimary';
import LoyaltyInsulinUsageFactors from '../components/dashboard/loyalty/loyaltyInsulinUsageFactors';

function LoyaltyCongratsInsights(props: any) {
    const dispatch = useDispatch();

    const anchorEvent = useSelector((state: RootState) => state.ui.anchorEvent);
    const app = SystemHelper.GetCleanState(
        useSelector((state: RootState) => state.app),
        appInitialState
    );

    const translate = (key: string, subs?: any) => UiHelper.Translate(app, key, subs);
    const patient = app.patient;
    const { dashboardBeg, dashboardEnd, dashboardHasReferrer } = props?.match?.params ?? {};
    /* istanbul ignore next: Jest dom doesnt provide actual rendering capability so unable to get scroll values */
    const handleScroll = (event: any) =>
        NavigationHelper.HandleScroll(
            undefined,
            scrollActive1,
            undefined,
            setScrollActive1,
            undefined,
            event?.target?.scrollTop
        );
    const cbLearnMoreClickFn = () => {
        sendAzureEvent(ConstantsHelper.analyticsEventNames.VIEW_OP5_LEARN_MORE, {
            weekOf: app?.dashboardBeg,
        });

        window.open(
            app?.currentLearningMaterialResource?.data?.linkToMoreResources,
            '__OP5_LEARN_MORE__',
            'noopener,noreferrer'
        );
    };
    const cbResourcesClickFn = () => {
        sendAzureEvent(ConstantsHelper.analyticsEventNames.VIEW_OP5_RESOURCES, {
            weekOf: app?.dashboardBeg,
        });

        window.open(
            SystemHelper.GetRuntimeConfig('REACT_APP_ABOUT_URL_LINK'),
            '__OP5_RESOURCES__',
            'noopener,noreferrer'
        );
    };
    const buttonCaption = app?.currentLearningMaterialResource?.data?.buttonText;

    useEffect(() => {
        if (
            !(
                app.dashboardBeg === dashboardBeg &&
                app.dashboardEnd === dashboardEnd &&
                app.dashboardHasReferrer === (dashboardHasReferrer === 'true')
            )
        ) {
            dispatch(
                dashboardMetaDataActionCreator({
                    dashboardBeg,
                    dashboardEnd,
                    dashboardHasReferrer: dashboardHasReferrer === 'true',
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dashboardBeg, dashboardEnd, dashboardHasReferrer]);

    const clearAnchorEvent = () => dispatch(setAnchorEvent({ anchorEvent: undefined }));
    const [scrollActive1, setScrollActive1] = useState(false);

    useLayoutEffect(() => {
        SystemHelper.AppInsightsEvent(app, 'InsightDetails()', 'Mounted');
        sendAzureEvent(ConstantsHelper.analyticsEventNames.VIEW_OP5_DETAILS, {
            weekOf: app?.dashboardBeg,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (patient.receivedData && !(app.dashboardEnd && app?.currentLearningMaterialResource?.data?.weekNumber)) {
            const payload = UiHelper.GetInsightsDateRange(dispatch, app);

            if (payload.beg && payload.end) {
                UiHelper.ClearErrors(dispatch);
                UiHelper.FetchDashboard(dispatch, payload.beg, payload.end, app);
                UiHelper.FetchLearningMaterialOp5Progress(dispatch, payload.beg, payload.end, app);
                UiHelper.FetchDashboardInsulinUsage(dispatch, payload.beg, payload.end, app);
                UiHelper.FetchDashboardInsulinUsageTrend(dispatch, payload.beg, payload.end, app);
                UiHelper.FetchDashboardSummary(dispatch, payload.beg, payload.end, app);
                UiHelper.FetchDashboardSummaryGlucoseTrend(dispatch, payload.beg, payload.end, app);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patient.receivedData, app.patient?.confidentialityAgreementAccepted, app.patient?.eulaAccepted]);

    useEffect(() => {
        if (anchorEvent && app?.currentLearningMaterialResource?.data?.weekNumber) {
            NavigationHelper.ScrollIntoView(anchorEvent, clearAnchorEvent);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [anchorEvent, app?.currentLearningMaterialResource?.data?.weekNumber]);

    // TODO:  Add tests for this effect under tech debt work
    useEffect(() => {
        if (
            !UtilityHelper.IsNull(app.dashboardBeg) &&
            !UtilityHelper.IsNull(app.dashboardEnd) &&
            (app.dashboardEnd !== app.currentLearningMaterialResource.id ||
                DateTimeHelper.GetIsoNow() > app.currentLearningMaterialResource.expires)
        ) {
            UiHelper.FetchLearningMaterialResources(dispatch, app.dashboardBeg, app.dashboardEnd, app);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [app.dashboardBeg, app.dashboardEnd, app.patient?.confidentialityAgreementAccepted, app.patient?.eulaAccepted]);

    if (!app?.currentLearningMaterialResource?.data?.weekNumber) {
        return null;
    }

    return (
        <div
            data-testid="loyalty-congrats-container"
            className={styleGeneral.body}
            onScroll={(event) => {
                handleScroll(event);
            }}
        >
            <div className={styleGeneral.stickToTopBack}>
                <BtnBack
                    url={NavigationHelper.GenUrlDashboard(app.dashboardBeg, app.dashboardEnd, app.dashboardHasReferrer)}
                />
            </div>
            <div
                className={clsx(styleGuide.report, styleGuide.solidBgWhite, styleGeneral.stickToTopLevel2)}
                data-testid="loyalty-congrats"
            >
                <div
                    className={clsx(
                        styleGuide.header,
                        styleGuide.solidBgWhite,
                        styleGeneral.dashboardZoomInScroll1OffScroll2Off
                    )}
                >
                    <div className={clsx(styleGuide.loyaltyTitle, scrollActive1 && styleGeneral.inScroll2)}>
                        {translate('loyalty.congrats.weekTitle', {
                            weekNumber: app?.currentLearningMaterialResource?.data?.weekNumber,
                        })}
                    </div>
                    <div className={clsx(styleGuide.dateRange, scrollActive1 && styleGuide.dateRangeShort)}>
                        {app?.dashboardEnd && (
                            <div
                                className={clsx(styleGuide.caption, scrollActive1 && styleGeneral.inScroll)}
                                data-testid="loyalty-congrats-dashboardEnd"
                            >
                                {DateTimeHelper.FormatDateRange(app.dashboardEnd)}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className={styleGuide.solidBg} data-testid="loyalty-congrats-frame">
                <div className={clsx(styleGuide.insightsFullWidth, styleGeneral.dashboardSummary)}>
                    <div className={styleGuide.InsightCard}>
                        <div className={styleGuide.whiteBackground}>
                            <div className={styleGuide.header}>
                                <div className={clsx(styleGeneral.table, styleGuide.topGutter, styleGuide.linkCursor)}>
                                    <Tooltip title={buttonCaption}>
                                        <img
                                            src={app?.currentLearningMaterialResource?.data?.featuredImage}
                                            className={clsx(styleGuide.blockWidth)}
                                            alt={translate('loyalty.congrats.therapy.imgText')}
                                            onClick={cbLearnMoreClickFn}
                                        />
                                    </Tooltip>
                                </div>
                                <div className={styleGuide.loyaltyHeader}>
                                    <div className={styleGuide.titleAlt}>
                                        {app?.currentLearningMaterialResource?.data?.title}
                                    </div>
                                </div>
                                <div className={styleGuide.loyaltyBody}>
                                    <div className={styleGuide.text}>
                                        {app?.currentLearningMaterialResource?.data?.body}
                                    </div>
                                </div>
                            </div>
                            <BtnPrimary caption={buttonCaption} cbClickFn={cbLearnMoreClickFn} />
                            <div className={styleGuide.header}>
                                <div className={clsx(styleGuide.lineAlt2)}></div>
                                <div className={styleGuide.loyaltyFooter}>
                                    <div className={styleGuide.textAlt}>
                                        {translate('loyalty.congrats.therapy.read')}
                                        <div className={clsx(styleGeneral.link)} onClick={cbResourcesClickFn}>
                                            {translate('loyalty.congrats.therapy.resourcesA')}
                                            <sup>&#174;</sup>&nbsp;
                                            {translate('loyalty.congrats.therapy.resourcesB')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <LoyaltyInsulinUsageFactors app={app} translate={translate} warningsOnly />
        </div>
    );
}

export default LoyaltyCongratsInsights;
