import { isNil } from 'lodash';
import { Link } from 'react-router-dom';
import { Grid } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

import { ICallback, IContributingEvent, IInsightAttr, ITimeRange, ITranslator } from '../../types';
import { InsightInsightsProp } from '../../model/insightInsightsProp';
import { IAppState } from '../../store/app/app.types';
import ConstantsHelper from '../../helpers/ConstantsHelper';
import DateTimeHelper from '../../helpers/DateTimeHelper';
import iconArrowRight from '../../assets/images/icons-arrow-right.svg';
import styleGuide from '../../styles/styleGuide.module.scss';
import EventHelper from '../../helpers/EventHelper';

function EventCell({
    app,
    translate,
    anchor,
    cbClickFn,
    url,
    contributingEvent,
    insightAttributes,
}: {
    app: IAppState;
    translate: ITranslator;
    anchor: string;
    cbClickFn: ICallback<void>;
    url: string;
    contributingEvent: IContributingEvent;
    insightAttributes: IInsightAttr;
    insightType: InsightInsightsProp.InsightTypeEnum;
}) {
    let timeRangeData: ITimeRange;

    if (isNil(contributingEvent)) {
        return null;
    } else {
        timeRangeData = DateTimeHelper.FormatDateTimeRange(app, contributingEvent.beg, contributingEvent.end, false);
    }

    const renderDate = <div className={styleGuide.dateRange}>{timeRangeData?.date}</div>;

    const renderReading = (
        <div className={styleGuide.reading}>
            <span className={clsx(styleGuide.value, insightAttributes?.style)}>
                {EventHelper.GetEgvLabel(translate, contributingEvent?.extremeBGValue, false)}
            </span>

            <span className={styleGuide.unit}>{ConstantsHelper.ReadingsUnit}</span>
        </div>
    );

    return (
        <div id={anchor} className={styleGuide.eventCell}>
            <Link
                className={styleGuide.link}
                to={url}
                onClick={() =>
                    cbClickFn({
                        anchorEvent: anchor,
                        contributingEventId: contributingEvent.eventId,
                        contributingEventBeg: contributingEvent.beg,
                        contributingEventEnd: contributingEvent.end,
                    })
                }
            >
                <div className={clsx(styleGuide.whiteBackground, styleGuide.card, styleGuide.elevated)}>
                    <Grid alignItems="center" container spacing={2} wrap="nowrap">
                        <Grid className={styleGuide.eventCellColumn} item xs>
                            {renderReading}

                            {renderDate}

                            <img
                                alt={translate('more')}
                                className={styleGuide.cardIconArrowRight}
                                src={iconArrowRight}
                            />
                        </Grid>
                    </Grid>
                </div>
            </Link>
        </div>
    );
}

export default EventCell;
