import { IAppState } from '../../app.types';
import { LearningMaterialProgress } from '../../../../model/learningMaterialProgress';
import ConstantsHelper from '../../../../helpers/ConstantsHelper';
import UtilityHelper from '../../../../helpers/UtilityHelper';
import UiHelper from '../../../../helpers/UiHelper';
import AnI18NextLibHelper from '../../../../helpers/AnI18NextLibHelper';
import DateTimeHelper from '../../../../helpers/DateTimeHelper';

const fetchLearningMaterialOp5ProgressFulfilled = (state: IAppState, action: any) => {
    const callKey = UtilityHelper.MakeHttpCallKey(
        ConstantsHelper.httpCallAndErrorKeys.fetchLearningMaterialOp5Progress,
        [action.meta?.arg?.beg, action.meta?.arg?.end]
    );
    if (callKey) {
        delete state.activeHttpCalls[callKey];
    }
    if (action.payload) {
        const currentLearningMaterialProgress: LearningMaterialProgress = {
            ...action.payload.data.item.progress,
        };
        state.currentLearningMaterialProgress = {
            id: state.dashboardEnd,
            data: currentLearningMaterialProgress,
            expires: DateTimeHelper.AddSecs(DateTimeHelper.GetIsoNow(), 60),
        };
        state.patient.isOnboarded = (currentLearningMaterialProgress.weekNumber ?? -1) === -1;
        state.errors[callKey] = UiHelper.CreateErrorMessage({
            level: ConstantsHelper.ErrorLevels.success,
            message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.success'),
            title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.failedLearningMaterialProgress'),
        });
    }
    state.loadingSemaphore--;
};

export default fetchLearningMaterialOp5ProgressFulfilled;
