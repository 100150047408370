import clsx from 'clsx';
import React, { createRef, useEffect, useState } from 'react';

import { ITranslator } from '../../types';
import { IInsight } from '../../store/app/app.types';
import InsightHelper from '../../helpers/InsightHelper';
import styleGeneral from '../../styles/general.module.scss';
import styleGuide from '../../styles/styleGuide.module.scss';

function TimeOfDayPatternHeader({
    insightPattern,
    parentScrollNode = null,
    translate,
}: {
    insightPattern: IInsight;
    parentScrollNode?: React.ReactNode;
    translate: ITranslator;
}) {
    const [isPinned, setIsPinned] = useState<boolean>(false);
    const rootRef = createRef<HTMLDivElement>();
    const topPosition = 105;

    useEffect(() => {
        const element = rootRef.current;

        const observer = new IntersectionObserver(([e]) => setIsPinned(e.intersectionRatio < 1), {
            root: parentScrollNode as HTMLDivElement,
            rootMargin: `-${topPosition + 1}px 0px 0px 0px`,
            threshold: [1],
        });

        observer.observe(element);

        return () => {
            observer.unobserve(element);
        };
    }, [parentScrollNode, rootRef]);

    return (
        <div
            className={clsx(styleGuide.banner, styleGuide.timeOfDayBanner, styleGeneral.layerTopmostLess3, {
                [styleGuide.pinned]: isPinned,
            })}
            ref={rootRef}
            style={{
                top: topPosition,
            }}
        >
            <div className={styleGuide['SubtextDark-GreyLeft']}>
                {translate('youHaveAPatternOfTypeBetween', { type: insightPattern.patternType })}
            </div>

            <div className={clsx(styleGuide.timeOfDayRangeHeading, styleGuide.H1LeftBlack)}>
                {InsightHelper.GetTimeRangeCaption(insightPattern)}
            </div>
        </div>
    );
}

export default TimeOfDayPatternHeader;
