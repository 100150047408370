import ConstantsHelper from './ConstantsHelper';
import DateTimeHelper from './DateTimeHelper';
import StringHelper from './StringHelper';
import AnI18NextLibHelper from './AnI18NextLibHelper';
import UtilityHelper from './UtilityHelper';
import EventHelper from './EventHelper';

import { insightParentIdPrefix } from '../constants';
import {
    Insight3,
    InsightElement,
    InsightInsightsProp,
    InsightTimeOfDayInsightsProp,
    InsightTimeOfDayInsightsPropInner,
    PatternType,
    TimeOfDayType,
} from '../model/models';
import { IDayToFlag, IInsightAttr, ITranslator, IObjectHash } from '../types';
import { IAppState, IInsight } from '../store/app/app.types';
import styleGuide from '../styles/styleGuide.module.scss';
import iconClock from '../assets/images/icon-clock.svg';
import iconBolus from '../assets/images/icon-bolus.svg';

export default class InsightHelper {
    private static getInsightAttributesA = (
        translate: ITranslator,
        app: IAppState,
        insight: IInsight,
        insightAttr: IInsightAttr
    ) => {
        switch (insight.patternType) {
            case PatternType.PatternTypeEnum.High:
                insightAttr.title = `${translate('highest')}:`;
                insightAttr.nameAlt = AnI18NextLibHelper.Translate(
                    app?.anI18Nextlib,
                    ConstantsHelper.ReadingsLabelHigh
                );
                insightAttr.style = styleGuide.dayBoxYellow2;
                insightAttr.style2 = styleGuide.dayBoxGray3;
                insightAttr.styleAlt = styleGuide.dayBoxYellow;
                break;
            case PatternType.PatternTypeEnum.Low:
                insightAttr.title = `${translate('lowest')}:`;
                insightAttr.nameAlt = AnI18NextLibHelper.Translate(app?.anI18Nextlib, ConstantsHelper.ReadingsLabelLow);
                insightAttr.style = styleGuide.dayBoxRed2;
                insightAttr.style2 = styleGuide.dayBoxGray3;
                insightAttr.styleAlt = styleGuide.dayBoxRed;
                break;
        }

        insightAttr.name = insightAttr.nameAlt.toLowerCase();
        insightAttr.title2 = `${translate('duration')}:`;
    };

    private static getInsightAttributesSubB = (insight: IInsight, insightAttr: IInsightAttr) => {
        switch (insight.insightType) {
            case InsightInsightsProp.InsightTypeEnum.TimeOfDay:
                insightAttr.iconAlt = 'Clock';
                insightAttr.iconObj = iconClock;
                break;
            case InsightInsightsProp.InsightTypeEnum.Bolus:
                insightAttr.iconAlt = 'Bolus';
                insightAttr.iconObj = iconBolus;
                break;
        }

        const evtCnt = insight.windowEgvEvent?.length ?? 0;

        return { evtCnt };
    };

    private static getInsightAttributesB = (
        translate: ITranslator,
        app: IAppState,
        insight: IInsight,
        insightAttr: IInsightAttr
    ) => {
        const { evtCnt } = InsightHelper.getInsightAttributesSubB(insight, insightAttr);

        if (insight.insightType === InsightInsightsProp.InsightTypeEnum.Bolus) {
            insightAttr.scrn1Title = StringHelper.Pluralize(
                0,
                translate('eventAfterBolus', { name: insightAttr.nameAlt }),
                false
            );
            insightAttr.scrn1SubTitle = AnI18NextLibHelper.Translate(app?.anI18Nextlib, 'youHadEventsAfterYouBolus', {
                eventCount: StringHelper.Pluralize(
                    Math.min(evtCnt, ConstantsHelper.DashboardMaxEventsPerBolusInsight),
                    `${insightAttr.name}`
                ),
            });
            insightAttr.scrn2Title = translate('bolusPattern');
            insightAttr.scrn3Title = insightAttr.scrn1Title;
        } else {
            const { i18nPrefix, timeOfDayBlock } = DateTimeHelper.GetTimeOfDayName(app, insight.overlapStartTime);

            insightAttr.scrn1Title = `${i18nPrefix} ${StringHelper.Pluralize(0, insightAttr.nameAlt, false)}`;
            insightAttr.scrn1SubTitle = translate('between', {
                timeOfDayBlock,
            });

            const timeOfDayPatternLength = this.GetTimeOfDayPatterns(app, insight).length;

            insightAttr.scrn2Title = StringHelper.Pluralize(
                timeOfDayPatternLength,
                translate('numberOfPatterns', {
                    count: timeOfDayPatternLength,
                }),
                false
            );

            insightAttr.scrn3Title = `${i18nPrefix} ${insightAttr.nameAlt}`;
        }

        insightAttr.edNotes = UtilityHelper.GetEdNotes(translate, insight.patternType);
    };

    public static GetInsightAttributes = (app: IAppState, insight: IInsight, translate: ITranslator): IInsightAttr => {
        const insightAttr: IInsightAttr = {};

        if (insight) {
            InsightHelper.getInsightAttributesA(translate, app, insight, insightAttr);
            InsightHelper.getInsightAttributesB(translate, app, insight, insightAttr);
        }

        return insightAttr;
    };

    private static getFlaggedDays = (app: IAppState, daysReported: IDayToFlag[]): IDayToFlag[] =>
        DateTimeHelper.GetDayLetters(app).map((d) => {
            const matchDay = daysReported.find((e) => e.dayName === d.dayName);

            return {
                ...d,
                ...matchDay,
                flagged: !!matchDay,
            };
        });

    public static GetFlaggedDays = (app: IAppState, insight: IInsight): IDayToFlag[] => {
        return InsightHelper.getFlaggedDays(app, [
            ...new Set(insight?.windowEgvEvent?.map((c) => DateTimeHelper.GetDayDataForTimeStamp(c.beg, app))),
        ]);
    };

    public static GetFlaggedDaysForSummary = (app: IAppState, summary: Insight3): IDayToFlag[] => {
        return InsightHelper.getFlaggedDays(app, [
            ...new Set(summary?.celebrationDays?.map((c) => DateTimeHelper.GetDayDataForTimeStamp(c.date, app))),
        ]);
    };

    public static ApplyInsightToCache = (cachedInsights: IObjectHash<IInsight>, newInsight: IInsight) => {
        cachedInsights[newInsight.insightId] = {
            ...cachedInsights[newInsight.insightId],
            ...newInsight,
        };
    };

    public static PopulateSuperInsight = (
        state: IAppState,
        insightIds: string[],
        superInsightOrig: IInsight,
        newInsight: IInsight
    ): IInsight => {
        let superInsight = {
            ...superInsightOrig,
        };

        if (!superInsight.insightId) {
            superInsight = {
                ...newInsight,
                insightId: `${insightParentIdPrefix}${newInsight.insightId}`,
                windowEgvEvent: [],
                childInsightIds: [],
            };

            insightIds.push(superInsight.insightId);

            InsightHelper.ApplyInsightToCache(state.cachedInsights, superInsight);
        }

        newInsight.windowEgvEvent.forEach((evt) => superInsight.windowEgvEvent.push(evt));
        superInsight.childInsightIds.push(newInsight.insightId);
        InsightHelper.ApplyInsightToCache(state.cachedInsights, newInsight);

        return superInsight;
    };

    public static GetTimeOfDayPatterns = (app: IAppState, insight: IInsight): IInsight[] => {
        return insight.childInsightIds?.map((childInsightId) => app.cachedInsights[childInsightId]) ?? [];
    };

    public static GetTimeRangeCaption = (insight: IInsight): string => {
        return `${DateTimeHelper.Format24HrToAmPm(insight.overlapStartTime)} - ${DateTimeHelper.Format24HrToAmPm(
            insight.overlapEndTime
        )}`;
    };

    public static ConvertInsightIdSingleToChildAndParent = (id: string): { child: string; parent: string } => {
        const flds = `${id ?? ''}:`.split(':');

        return {
            child: flds[0].replace(insightParentIdPrefix, ''),
            parent: flds[1],
        };
    };

    public static ConvertInsightIdsChildAndParentToSingle = (idChild: string, idParent: string): string => {
        return `${idChild ?? ''}:${idParent ?? ''}`;
    };

    public static PopulateInsightData = (state: IAppState, data: InsightElement[], insightIds: string[]) => {
        /**
         * Scan low then high pattern groups
         */
        [PatternType.PatternTypeEnum.Low, PatternType.PatternTypeEnum.High].forEach((patternType) => {
            const patternGroup: InsightElement = data.find((e) => e.patternType === patternType);

            if (patternGroup) {
                patternGroup.insights?.forEach((newInsightBolusObj: InsightInsightsProp) => {
                    const newInsight: IInsight = {
                        ...newInsightBolusObj,
                        windowEgvEvent: EventHelper.OrderEvents(
                            newInsightBolusObj.windowEgvEvent,
                            true,
                            newInsightBolusObj.patternType === PatternType.PatternTypeEnum.High
                        ),
                    };

                    insightIds.push(newInsight.insightId);

                    state.cachedInsights[newInsight.insightId] = {
                        ...state.cachedInsights[newInsight.insightId],
                        ...newInsight,
                    };
                });

                let insightsMorning: IInsight = {
                    insightId: null,
                };

                let insightsAfternoon: IInsight = {
                    insightId: null,
                };

                let insightsEvening: IInsight = {
                    insightId: null,
                };

                let insightsNight: IInsight = {
                    insightId: null,
                };

                patternGroup.timeOfDayInsights?.forEach((newInsightTodParentObj: InsightTimeOfDayInsightsProp) => {
                    newInsightTodParentObj.insights.forEach((newInsightTodObj: InsightTimeOfDayInsightsPropInner) => {
                        const newInsight: IInsight = {
                            ...newInsightTodObj,
                            timeOfDay: newInsightTodParentObj.timeOfDay,
                            windowEgvEvent: EventHelper.OrderEvents(
                                newInsightTodObj.windowEgvEvent,
                                false,
                                newInsightTodObj.patternType === PatternType.PatternTypeEnum.High
                            ),
                        } as IInsight;

                        /**
                         * Here have a new single TOD insight that we need to add to its corresponding parent super insight
                         * where super insights are the ones that are displayed in the dashboard while the children of each
                         * super insight is what is displayed in insight details page
                         */
                        switch (newInsight.timeOfDay) {
                            case TimeOfDayType.TimeOfDayEnum.Morning:
                                insightsMorning = InsightHelper.PopulateSuperInsight(
                                    state,
                                    insightIds,
                                    insightsMorning,
                                    newInsight
                                );

                                break;
                            case TimeOfDayType.TimeOfDayEnum.Afternoon:
                                insightsAfternoon = InsightHelper.PopulateSuperInsight(
                                    state,
                                    insightIds,
                                    insightsAfternoon,
                                    newInsight
                                );

                                break;
                            case TimeOfDayType.TimeOfDayEnum.Evening:
                                insightsEvening = InsightHelper.PopulateSuperInsight(
                                    state,
                                    insightIds,
                                    insightsEvening,
                                    newInsight
                                );

                                break;
                            case TimeOfDayType.TimeOfDayEnum.Nighttime:
                                insightsNight = InsightHelper.PopulateSuperInsight(
                                    state,
                                    insightIds,
                                    insightsNight,
                                    newInsight
                                );

                                break;
                        }
                    });
                });
            }
        });
    };
}
