import { IAppState } from '../../app.types';
import ConstantsHelper from '../../../../helpers/ConstantsHelper';
import UtilityHelper from '../../../../helpers/UtilityHelper';
import UiHelper from '../../../../helpers/UiHelper';
import AnI18NextLibHelper from '../../../../helpers/AnI18NextLibHelper';
import EventHelper from '../../../../helpers/EventHelper';

const fetchInsightEventModesFulfilled = (state: IAppState, action: any) => {
    const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.fetchInsightEventModes, [
        action.meta?.arg?.insightId,
        action.meta?.arg?.eventId,
    ]);
    if (callKey) {
        delete state.activeHttpCalls[callKey];
    }
    state.currentReadingCombo = EventHelper.OrderEventReadings(
        action.payload.id,
        state.cachedInsights[action.payload.id2],
        state.readingsInfo.fillGaps,
        {
            ...state.currentReadingCombo,
            ...action.payload?.data,
        }
    );
    state.errors[callKey] = UiHelper.CreateErrorMessage({
        level: ConstantsHelper.ErrorLevels.success,
        message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.success'),
        title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.eventModes'),
    });
    state.loadingSemaphore--;
};

export default fetchInsightEventModesFulfilled;
