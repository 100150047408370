import { createAsyncThunk } from '@reduxjs/toolkit';
import { StatusCodes } from 'http-status-codes';

import { IAppState, ICachedResponse, IIDataItemReadings, IPatient } from './app.types';

import {
    DataResponseInsight3,
    DataResponseInsight3GlucoseTrend,
    DataResponseInsulinUsageItem,
    DataResponseInsulinUsageTrendItem,
    LearningMaterialProgressResponseItem,
    LearningMaterialResourceResponseItem,
    PatientProfileBody,
    Clinic,
} from '../../model/models';
import {
    IDashboardRequest,
    IInsightFeedback,
    IDashboardFetchPayload,
    IDashboardSummaryFetchPayload,
    IDashboardSummaryGlucoseTrendFetchPayload,
    IReadingSub,
    IAcceptClinicInvitationParams,
    IDenyClinicInvitationParams,
} from '../../types';
import { RootState } from '../store';
import { IAuthenticationState } from '../authentication/authentication.types';
import AnI18NextLibHelper from '../../helpers/AnI18NextLibHelper';
import DateTimeHelper from '../../helpers/DateTimeHelper';
import InsightHelper from '../../helpers/InsightHelper';
import SystemHelper from '../../helpers/SystemHelper';
import UtilityHelper from '../../helpers/UtilityHelper';

// https://www.newline.co/@bespoyasov/how-to-use-thunks-with-redux-toolkit-and-typescript--1e65fc64
type Error = {
    message: string;
};

export const sendFeedback = createAsyncThunk<IInsightFeedback, IInsightFeedback, { rejectValue: Error }>(
    'patient​/sendFeedback',
    async (feedback: IInsightFeedback, { getState, rejectWithValue }: any) => {
        const state = getState() as RootState;
        const appState = state.app;
        const authenticationState = state.authentication;

        const url = `${appState.endpointPwdUrlPrefix}/patient/insights/feedback`;
        const response = await SystemHelper.Fetch(
            appState,
            authenticationState,
            url,
            {
                method: 'POST',
                body: JSON.stringify({ feedback }),
            },
            true
        );

        if (!(response.status === StatusCodes.OK || response.status === StatusCodes.NO_CONTENT)) {
            return rejectWithValue({
                message: `${AnI18NextLibHelper.Translate(appState?.anI18Nextlib, 'errors.failedFeedback')} (${
                    response.msg
                })`,
            });
        }

        return feedback;
    }
);

export const updateProfile = createAsyncThunk<PatientProfileBody, PatientProfileBody, { rejectValue: Error }>(
    'patient​/updateProfile',
    async (updatePayload: PatientProfileBody, { getState, rejectWithValue }: any) => {
        const state = getState() as RootState;
        const appState = state.app;
        const authenticationState = state.authentication;

        const url = `${appState.endpointPwdUrlPrefix}/patient/profile`;
        const response = await SystemHelper.Fetch(
            appState,
            authenticationState,
            url,
            {
                method: 'POST',
                body: JSON.stringify(updatePayload),
            },
            true
        );

        if (!(response.status === StatusCodes.OK || response.status === StatusCodes.NO_CONTENT)) {
            return rejectWithValue({
                message: `${AnI18NextLibHelper.Translate(appState?.anI18Nextlib, 'errors.failedProfileUpdate')} (${
                    response.msg
                })`,
            });
        }

        return updatePayload;
    }
);

const fetchProfileSub = async (
    appState: IAppState,
    authenticationState: IAuthenticationState,
    rejectWithValue: any
): Promise<IPatient> => {
    // Use local token data since they may not have been stored in Redux store yet
    const url = `${appState.endpointPwdUrlPrefix}/patient/profile?`;
    const response = await SystemHelper.Fetch(appState, authenticationState, url, undefined, undefined, true);
    const payload: IPatient = response?.data?.data?.item ?? {};
    const status = response.status;

    payload.reportDaysAvailableString = '0';
    payload.isEnrolled = false;
    payload.isUnenrolled = false;
    payload.isOnboarded = false;
    payload.receivedData = true;
    payload.userType = 'patient';

    /*
        There are 4 possible cases which will trigger 3 different UX on front end:
            a) User in PodderCentral and enrolled in Discover:
                Profile in database?
                Yes: 200 OK
                No:
                    Trigger ETL (pull data into the database in real-time):
                        Success: Proceed with “d”
                        Failure: 424 Failed Dependency, show “Oh No” UX
            b) In PodderCentral and never enrolled in Discover:
                enrollmentStatus == true: 200 OK
                enrollmentStatus == false: 401 Unauthorized, show “You are unenrolled” UX
            c) In PodderCentral and chose to unenroll:
                enrollmentStatus == true: 200 OK
                enrollmentStatus == false: 401 Unauthorized, show “You are unenrolled” UX
            d) In PodderCentral and enrollment pending:
                enrollmentPending == false*: 200 OK
                enrollmentPending == true*: 404 Not Found, show “Something is missing” UX.


        200 Normal ops
        401 Show "You are unenrolled" UX
        404 Show "Something is missing" UX
        else Show "Oh No" UX
    */
    if (status === StatusCodes.OK && !UtilityHelper.IsNull(payload.firstJoined)) {
        payload.isEnrolled = true;
    } else if (status === StatusCodes.UNAUTHORIZED) {
        payload.isUnenrolled = true;
    } else if (status !== StatusCodes.NOT_FOUND) {
        return rejectWithValue({ message: `${response.msg}.` });
    }

    if (UtilityHelper.IsNull(payload.clinicName)) {
        payload.clinicName = 'Clinic Name Here';
    }

    if (UtilityHelper.IsNull(payload.physicianName)) {
        payload.physicianName = 'Physician Name Here';
    }

    if (UtilityHelper.IsNull(payload.dateOfBirth)) {
        payload.dateOfBirth = DateTimeHelper.GetIsoNow();
    }

    return payload;
};

export const fetchProfile = createAsyncThunk<IPatient, any, { rejectValue: Error }>(
    'patient/fetchProfile',
    async (params: any, { getState, rejectWithValue }: any) => {
        const state = getState() as RootState;
        const appState = state.app;
        const authenticationState = state.authentication;

        if (
            !(
                authenticationState?.oktaData?.access &&
                authenticationState?.oktaData?.aud &&
                authenticationState?.oktaData?.id &&
                authenticationState?.oktaData?.expires &&
                DateTimeHelper.GetDurationInSeconds(
                    DateTimeHelper.GetIsoNow(),
                    authenticationState?.oktaData?.expires
                ) > 0
            )
        ) {
            return rejectWithValue({
                message: AnI18NextLibHelper.Translate(appState?.anI18Nextlib, 'signIn.signInRequired'),
            });
        } else {
            return fetchProfileSub(appState, authenticationState, rejectWithValue);
        }
    }
);

export const fetchBackEndId = createAsyncThunk<string, any, { rejectValue: Error }>(
    'patient​/fetchBackEndId',
    async (params: any, { getState, rejectWithValue }: any) => {
        const state = getState() as RootState;
        const appState = state.app;
        const authenticationState = state.authentication;

        const queryParams = [''];
        const url = `${appState.endpointPwdUrlPrefix}/?${queryParams.join('&')}`;
        const response = await SystemHelper.Fetch(appState, authenticationState, url, undefined, undefined, true, true);

        if (response.status !== StatusCodes.OK) {
            return rejectWithValue({
                message: `${response.msg}.`,
            });
        }

        return response.data;
    }
);

interface PatientSmsNumber {
    smsNumber: string;
}

export const saveAndConfirmSmsNumber = createAsyncThunk(
    'patient/saveAndConfirmSmsNumber',
    async (updatePayload: PatientSmsNumber, { getState, rejectWithValue }: any) => {
        const state = getState() as RootState;
        const appState = state.app;
        const authenticationState = state.authentication;

        const url = `${appState.endpointPwdUrlPrefix}/patient/profile/save-and-confirm-sms-number`;

        const response = await SystemHelper.Fetch(
            appState,
            authenticationState,
            url,
            {
                method: 'PUT',
                body: JSON.stringify(updatePayload),
            },
            true
        );

        if (response.status !== StatusCodes.OK) {
            return rejectWithValue({
                message: `${AnI18NextLibHelper.Translate(appState?.anI18Nextlib, 'errors.failedSmsNumberUpdate')}`,
            });
        }

        return updatePayload;
    }
);

export const fetchLearningMaterialResources = createAsyncThunk<
    ICachedResponse<LearningMaterialResourceResponseItem>,
    IDashboardRequest,
    { rejectValue: Error }
>('patient​/fetchLearningMaterialResources', async (params: IDashboardRequest, { getState, rejectWithValue }: any) => {
    const state = getState() as RootState;
    const appState = state.app;
    const authenticationState = state.authentication;

    const learningMaterialResourceItem: LearningMaterialResourceResponseItem = {
        item: null,
    };
    const queryParams = [`beg=${params.beg}`, `end=${params.end}`, `i18n=${params.i18n ?? 'en_US'}`];
    const url = `${appState.endpointPwdUrlPrefix}/patient/learning-material/resources?${queryParams.join('&')}`;
    const response = await SystemHelper.Fetch(appState, authenticationState, url, undefined, false, true);
    const haveValidStatus =
        response.status === StatusCodes.OK ||
        response.status === StatusCodes.NOT_FOUND ||
        response.status === StatusCodes.BAD_REQUEST ||
        response.status === StatusCodes.INTERNAL_SERVER_ERROR;

    if (response.status === StatusCodes.OK) {
        learningMaterialResourceItem.item = response.data?.data?.item;
    } else {
        learningMaterialResourceItem.item = {
            resources: {},
        };
    }

    if (!haveValidStatus) {
        return rejectWithValue({
            message: `${AnI18NextLibHelper.Translate(
                appState?.anI18Nextlib,
                'errors.failedLearningMaterialResource'
            )} (${response.msg})`,
        });
    }

    return {
        data: learningMaterialResourceItem,
    };
});

export const fetchLearningMaterialOp5Progress = createAsyncThunk<
    ICachedResponse<LearningMaterialProgressResponseItem>,
    IDashboardRequest,
    { rejectValue: Error }
>(
    'patient​/fetchLearningMaterialOp5Progress',
    async (params: IDashboardRequest, { getState, rejectWithValue }: any) => {
        const state = getState() as RootState;
        const appState = state.app;
        const authenticationState = state.authentication;

        const learningMaterialProgress: LearningMaterialProgressResponseItem = {
            item: null,
        };
        const queryParams = [`beg=${params.beg}`, `end=${params.end}`, `i18n=${params.i18n ?? 'en_US'}`];
        const url = `${appState.endpointPwdUrlPrefix}/patient/learning-material/op5-progress?${queryParams.join('&')}`;
        const response = await SystemHelper.Fetch(appState, authenticationState, url, undefined, false, true);
        const haveValidStatus =
            response.status === StatusCodes.OK ||
            response.status === StatusCodes.NOT_FOUND ||
            response.status === StatusCodes.BAD_REQUEST ||
            response.status === StatusCodes.INTERNAL_SERVER_ERROR;

        if (response.status === StatusCodes.OK) {
            learningMaterialProgress.item = response.data?.data?.item;
        } else {
            learningMaterialProgress.item = {
                progress: {},
            };
        }

        if (!haveValidStatus) {
            return rejectWithValue({
                message: `${AnI18NextLibHelper.Translate(
                    appState?.anI18Nextlib,
                    'errors.failedLearningMaterialProgress'
                )} (${response.msg})`,
            });
        }

        return {
            data: learningMaterialProgress,
        };
    }
);

export const fetchDashboardInsulinUsage = createAsyncThunk<
    ICachedResponse<DataResponseInsulinUsageItem>,
    IDashboardRequest,
    { rejectValue: Error }
>('patient​/fetchDashboardInsulinUsage', async (params: IDashboardRequest, { getState, rejectWithValue }: any) => {
    const state = getState() as RootState;
    const appState = state.app;
    const authenticationState = state.authentication;

    const dashboardInsulinUsage: DataResponseInsulinUsageItem = {
        item: null,
    };
    const queryParams = [`beg=${params.beg}`, `end=${params.end}`];
    const url = `${appState.endpointPwdUrlPrefix}/patient/dashboard/insulin-usage?${queryParams.join('&')}`;
    const response = await SystemHelper.Fetch(appState, authenticationState, url, undefined, false, true);
    const haveValidStatus =
        response.status === StatusCodes.OK ||
        response.status === StatusCodes.NOT_FOUND ||
        response.status === StatusCodes.BAD_REQUEST ||
        response.status === StatusCodes.INTERNAL_SERVER_ERROR;

    if (response.status === StatusCodes.OK) {
        dashboardInsulinUsage.item = response.data?.data?.item;
    } else {
        dashboardInsulinUsage.item = {
            dailyUsage: {},
            displayUsageExplanation: {},
        };
    }

    if (!haveValidStatus) {
        return rejectWithValue({
            message: `${AnI18NextLibHelper.Translate(appState?.anI18Nextlib, 'errors.failedDashboardInsulinUsage')} (${
                response.msg
            })`,
        });
    }

    return {
        data: dashboardInsulinUsage,
    };
});

export const fetchDashboardInsulinUsageTrend = createAsyncThunk<
    ICachedResponse<DataResponseInsulinUsageTrendItem>,
    IDashboardRequest,
    { rejectValue: Error }
>('patient​/fetchDashboardInsulinUsageTrend', async (params: IDashboardRequest, { getState, rejectWithValue }: any) => {
    const state = getState() as RootState;
    const appState = state.app;
    const authenticationState = state.authentication;

    const dashboardInsulinUsageTrend: DataResponseInsulinUsageTrendItem = {
        item: null,
    };
    const queryParams = [`beg=${params.beg}`, `end=${params.end}`];
    const url = `${appState.endpointPwdUrlPrefix}/patient/dashboard/insulin-usage/trend?${queryParams.join('&')}`;
    const response = await SystemHelper.Fetch(appState, authenticationState, url, undefined, false, true);
    const haveValidStatus =
        response.status === StatusCodes.OK ||
        response.status === StatusCodes.NOT_FOUND ||
        response.status === StatusCodes.BAD_REQUEST ||
        response.status === StatusCodes.INTERNAL_SERVER_ERROR;

    if (response.status === StatusCodes.OK) {
        dashboardInsulinUsageTrend.item = response.data?.data?.item;
    } else {
        dashboardInsulinUsageTrend.item = {
            weeklyTrend: {},
        };
    }

    if (!haveValidStatus) {
        return rejectWithValue({
            message: `${AnI18NextLibHelper.Translate(
                appState?.anI18Nextlib,
                'errors.failedDashboardInsulinUsageTrend'
            )} (${response.msg})`,
        });
    }

    return {
        data: dashboardInsulinUsageTrend,
    };
});

export const fetchDashboardSummary = createAsyncThunk<
    ICachedResponse<IDashboardSummaryFetchPayload>,
    IDashboardRequest,
    { rejectValue: Error }
>('patient​/fetchDashboardSummary', async (params: IDashboardRequest, { getState, rejectWithValue }: any) => {
    const state = getState() as RootState;
    const appState = state.app;
    const authenticationState = state.authentication;

    const dashboardSummaryFetchPayload: IDashboardSummaryFetchPayload = {
        item: null,
    };
    const queryParams = [`beg=${params.beg}`, `end=${params.end}`];
    const url = `${appState.endpointPwdUrlPrefix}/patient/dashboard/summary?${queryParams.join('&')}`;
    const response = await SystemHelper.Fetch(appState, authenticationState, url, undefined, false, true);

    if (response.status === StatusCodes.OK) {
        const wrapper: DataResponseInsight3 = response.data;

        dashboardSummaryFetchPayload.item = wrapper?.data?.item;
    } else if (response.status === StatusCodes.UNAUTHORIZED || response.status === StatusCodes.NOT_FOUND) {
        dashboardSummaryFetchPayload.item = [] as any;
    }

    if (dashboardSummaryFetchPayload.item === null) {
        return rejectWithValue({
            message: `${AnI18NextLibHelper.Translate(appState?.anI18Nextlib, 'errors.failedDashboardSummary')} (${
                response.msg
            })`,
        });
    }

    return {
        data: dashboardSummaryFetchPayload,
    };
});

export const fetchDashboardSummaryGlucoseTrend = createAsyncThunk<
    ICachedResponse<IDashboardSummaryGlucoseTrendFetchPayload>,
    IDashboardRequest,
    { rejectValue: Error }
>(
    'patient​/fetchDashboardSummaryGlucoseTrend',
    async (params: IDashboardRequest, { getState, rejectWithValue }: any) => {
        const state = getState() as RootState;
        const appState = state.app;
        const authenticationState = state.authentication;

        const dashboardSummaryGlucoseTrendFetchPayload: IDashboardSummaryGlucoseTrendFetchPayload = {
            item: null,
        };

        const queryParams = [`beg=${params.beg}`, `end=${params.end}`];
        const url = `${appState.endpointPwdUrlPrefix}/patient/dashboard/summary/glucose-trend?${queryParams.join('&')}`;
        const response = await SystemHelper.Fetch(appState, authenticationState, url, undefined, false, true);

        if (response.status === StatusCodes.OK) {
            const wrapper: DataResponseInsight3GlucoseTrend = response.data;

            dashboardSummaryGlucoseTrendFetchPayload.item = wrapper?.data?.item;
        } else if (response.status === StatusCodes.UNAUTHORIZED || response.status === StatusCodes.NOT_FOUND) {
            dashboardSummaryGlucoseTrendFetchPayload.item = [] as any;
        }

        if (dashboardSummaryGlucoseTrendFetchPayload.item === null) {
            return rejectWithValue({
                message: `${AnI18NextLibHelper.Translate(
                    appState?.anI18Nextlib,
                    'errors.failedDashboardSummaryGlucoseTrend'
                )} (${response.msg})`,
            });
        }

        return {
            id: params.endUtc,
            data: dashboardSummaryGlucoseTrendFetchPayload,
        };
    }
);

export const fetchDashboard = createAsyncThunk<
    ICachedResponse<IDashboardFetchPayload>,
    IDashboardRequest,
    { rejectValue: Error }
>('patient​/fetchDashboard', async (params: IDashboardRequest, { getState, rejectWithValue }: any) => {
    const state = getState() as RootState;
    const appState = state.app;
    const authenticationState = state.authentication;

    const dashboardFetchPayload: IDashboardFetchPayload = {
        items: null,
        totalCount: 0,
    };
    const queryParams = [`beg=${params.beg}`, `end=${params.end}`, `limit=${params.limit}`, `offset=${params.offset}`];
    const url = `${appState.endpointPwdUrlPrefix}/patient/dashboard?${queryParams.join('&')}`;
    const response = await SystemHelper.Fetch(appState, authenticationState, url, undefined, false, true);

    if (response.status === StatusCodes.OK) {
        const wrapper: IDashboardFetchPayload = response.data?.data;

        dashboardFetchPayload.items = wrapper.items;
        dashboardFetchPayload.totalCount = wrapper.totalCount;
    } else if (response.status === StatusCodes.UNAUTHORIZED || response.status === StatusCodes.NOT_FOUND) {
        dashboardFetchPayload.items = [];
    }

    if (dashboardFetchPayload.items === null) {
        return rejectWithValue({
            message: `${AnI18NextLibHelper.Translate(appState?.anI18Nextlib, 'errors.failedDashboard')} (${
                response.msg
            })`,
        });
    }

    return {
        id: params.end,
        data: dashboardFetchPayload,
    } as IDashboardFetchPayload;
});

export const fetchInsightEventReadings = createAsyncThunk<
    ICachedResponse<IIDataItemReadings>,
    IDashboardRequest,
    { rejectValue: Error }
>('patient​/fetchInsightEventReadings', async (params: IDashboardRequest, { getState, rejectWithValue }: any) => {
    const state = getState() as RootState;
    const appState = state.app;
    const authenticationState = state.authentication;

    let dataReadings;
    const insightId = InsightHelper.ConvertInsightIdSingleToChildAndParent(params?.insightId).child;
    const url = `${appState.endpointPwdUrlPrefix}/patient/insights/${insightId}/${params.eventId}/readings?`;
    const response = await SystemHelper.Fetch(appState, authenticationState, url, undefined, undefined, true);

    if (response.status !== StatusCodes.OK) {
        return rejectWithValue({
            message: `${AnI18NextLibHelper.Translate(appState?.anI18Nextlib, 'errors.failedEventReadings')} (${
                response.msg
            })`,
        });
    } else {
        const readings = response.data?.data?.item?.readings ?? [];

        dataReadings = {
            readings: readings,
        };
    }

    return {
        id: params.eventId,
        id2: insightId,
        data: {
            ...appState.currentReadingCombo,
            ...dataReadings,
            fetchedReadings: true,
        },
    };
});

export const fetchInsightEventBoluses = createAsyncThunk<
    ICachedResponse<IIDataItemReadings>,
    IDashboardRequest,
    { rejectValue: Error }
>('patient​/fetchInsightEventBoluses', async (params: IDashboardRequest, { getState, rejectWithValue }: any) => {
    const state = getState() as RootState;
    const appState = state.app;
    const authenticationState = state.authentication;

    let dataReadings;
    const insightId = InsightHelper.ConvertInsightIdSingleToChildAndParent(params?.insightId).child;
    const url = `${appState.endpointPwdUrlPrefix}/patient/insights/${insightId}/${params.eventId}/boluses?`;
    const response = await SystemHelper.Fetch(appState, authenticationState, url, undefined, undefined, true);

    if (
        !(
            response.status === StatusCodes.OK ||
            response.status === StatusCodes.NOT_FOUND ||
            response.status === StatusCodes.INTERNAL_SERVER_ERROR
        )
    ) {
        return rejectWithValue({
            message: `${AnI18NextLibHelper.Translate(appState?.anI18Nextlib, 'errors.failedEventBoluses')} (${
                response.msg
            })`,
        });
    } else {
        dataReadings = {
            boluses: response.data?.data?.item?.boluses ?? [],
        };
    }

    return {
        id: params.eventId,
        id2: insightId,
        data: {
            ...appState.currentReadingCombo,
            ...dataReadings,
            fetchedBoluses: true,
        },
    };
});

export const fetchInsightEventModes = createAsyncThunk<
    ICachedResponse<IReadingSub>,
    IDashboardRequest,
    { rejectValue: Error }
>('patient​/fetchInsightEventModes', async (params: IDashboardRequest, { getState, rejectWithValue }: any) => {
    const state = getState() as RootState;
    const appState = state.app;
    const authenticationState = state.authentication;

    let dataReadings;
    const insightId = InsightHelper.ConvertInsightIdSingleToChildAndParent(params?.insightId).child;
    const url = `${appState.endpointPwdUrlPrefix}/patient/insights/${insightId}/${params.eventId}/modes?`;
    const response = await SystemHelper.Fetch(appState, authenticationState, url, undefined, undefined, true);

    if (
        !(
            response.status === StatusCodes.OK ||
            response.status === StatusCodes.NOT_FOUND ||
            response.status === StatusCodes.INTERNAL_SERVER_ERROR
        )
    ) {
        return rejectWithValue({
            message: `${AnI18NextLibHelper.Translate(appState?.anI18Nextlib, 'errors.failedEventModes')} (${
                response.msg
            })`,
        });
    } else {
        dataReadings = {
            modes: response.data?.data?.item?.modes ?? [],
            podStatuses: response.data?.data?.item?.podStatuses ?? [],
            submodes: response.data?.data?.item?.submodes ?? [],
        };
    }

    return {
        id: params.eventId,
        id2: insightId,
        data: {
            ...appState.currentReadingCombo,
            ...dataReadings,
            fetchedModes: true,
        },
    };
});

export const fetchHcpReport = createAsyncThunk<number, IDashboardRequest, { rejectValue: Error }>(
    'patient​/fetchHcpReport',
    async (params: any, { getState, rejectWithValue }: any) => {
        const state = getState() as RootState;
        const appState = state.app;

        if ((params?.numDays ?? -1) < 0) {
            return rejectWithValue({
                message: `${AnI18NextLibHelper.Translate(appState?.anI18Nextlib, 'errors.invalidResponse')}`,
            });
        }

        return params?.numDays;
    }
);

export const fetchClinics = createAsyncThunk<Clinic[], any, { rejectValue: Error }>(
    'patient​/fetchClinics',
    async (params: any, { getState, rejectWithValue }: any) => {
        const state = getState() as RootState;
        const appState = state.app;
        const authenticationState = state.authentication;

        const url = `${appState.endpointPwdUrlPrefix}/patient/clinics`;
        const response = await SystemHelper.Fetch(appState, authenticationState, url, undefined, undefined, true);

        if (response.status !== StatusCodes.OK && response.status !== StatusCodes.NOT_FOUND) {
            return rejectWithValue({
                message: `${AnI18NextLibHelper.Translate(appState?.anI18Nextlib, 'errors.failedClinics')} (${
                    response.msg
                })`,
            });
        }

        return response.data?.data?.items ?? [];
    }
);

export const acceptClinicInvitation = createAsyncThunk<void, IAcceptClinicInvitationParams, { rejectValue: Error }>(
    'patient/acceptClinicInvitation',
    async (params, { getState, rejectWithValue }) => {
        const state = getState() as RootState;
        const appState = state.app;
        const authenticationState = state.authentication;

        const url = `${appState.endpointPwdUrlPrefix}/patient/invitations/${params.invitationId}/accept`;
        const response = await SystemHelper.Fetch(
            appState,
            authenticationState,
            url,
            {
                method: 'POST',
            },
            true
        );

        if (response.status !== StatusCodes.NO_CONTENT) {
            return rejectWithValue({
                message: `${AnI18NextLibHelper.Translate(
                    appState?.anI18Nextlib,
                    'errors.failedAcceptClinicInvitation'
                )} (${response.msg})`,
            });
        }

        return null;
    }
);

export const denyClinicInvitation = createAsyncThunk<void, IDenyClinicInvitationParams, { rejectValue: Error }>(
    'patient/denyClinicInvitation',
    async (params, { getState, rejectWithValue }) => {
        const state = getState() as RootState;
        const appState = state.app;
        const authenticationState = state.authentication;

        const url = `${appState.endpointPwdUrlPrefix}/patient/invitations/${params.invitationId}/deny`;
        const response = await SystemHelper.Fetch(
            appState,
            authenticationState,
            url,
            {
                method: 'POST',
            },
            true
        );

        if (response.status !== StatusCodes.NO_CONTENT) {
            return rejectWithValue({
                message: `${AnI18NextLibHelper.Translate(
                    appState?.anI18Nextlib,
                    'errors.failedDenyClinicInvitation'
                )} (${response.msg})`,
            });
        }

        return null;
    }
);
