import { v4 as uuidv4 } from 'uuid';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';

import { INotification } from '../types';
import { NotificationSeverity } from '../components/notification/AlertToast';
import { Clinic } from '../model/clinic';
import { ButtonColors } from '../components/promptModal/PromptModal.types';
import { RootState } from '../store/store';
import { appInitialState } from '../store/app/app.initialState';
import { acceptClinicInvitation, denyClinicInvitation, fetchClinics } from '../store/app/app.thunks';
import styleGeneral from '../styles/general.module.scss';
import styleSettings from '../styles/settings.module.scss';
import ClinicDetails from '../components/settings/ClinicDetails';
import UiHelper from '../helpers/UiHelper';
import SystemHelper from '../helpers/SystemHelper';
import ConstantsHelper from '../helpers/ConstantsHelper';
import UtilityHelper from '../helpers/UtilityHelper';
import Notification from '../components/notification/Notification';
import PromptModal from '../components/promptModal/PromptModal';

function Settings() {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [notification, setNotification] = useState<INotification | undefined>();
    const [showDenyClinicConfirmationModal, setShowDenyClinicConfirmationModal] = useState(false);
    const [clinicToDeny, setClinicToDeny] = useState<Clinic | null>(null);

    const app = SystemHelper.GetCleanState(
        useSelector((state: RootState) => state.app),
        appInitialState
    );

    useEffect(() => {
        const acceptClinicInvitationCallKey = UtilityHelper.MakeHttpCallKey(
            ConstantsHelper.httpCallAndErrorKeys.acceptClinicInvitation
        );
        const denyClinicInvitationCallKey = UtilityHelper.MakeHttpCallKey(
            ConstantsHelper.httpCallAndErrorKeys.denyClinicInvitation
        );

        if (
            isLoading &&
            (app.errors[acceptClinicInvitationCallKey]?.level === ConstantsHelper.ErrorLevels.success ||
                app.errors[denyClinicInvitationCallKey]?.level === ConstantsHelper.ErrorLevels.success)
        ) {
            dispatch(fetchClinics(null));
            setIsLoading(false);
        }
    }, [dispatch, app.errors, isLoading]);

    const handleDontShareDataClick = async (clinic: Clinic) => {
        setClinicToDeny(clinic);
        setShowDenyClinicConfirmationModal(true);
    };

    const handleShareDataClick = async (invitationId: string) => {
        setIsLoading(true);

        try {
            const result = await dispatch(acceptClinicInvitation({ invitationId }));
            unwrapResult(result as any);

            setNotification({
                message: translate('settings.dataSharedSuccessMessage'),
                severity: NotificationSeverity.Success,
            });
        } catch (error) {
            setIsLoading(false);
        }
    };

    const handleConfirmDenyClinicModal = async () => {
        setIsLoading(true);
        setShowDenyClinicConfirmationModal(false);

        try {
            const result = await dispatch(denyClinicInvitation({ invitationId: clinicToDeny.invitationId }));
            unwrapResult(result as any);

            setNotification({
                message: translate('settings.dataNotSharedSuccessMessage'),
                severity: NotificationSeverity.Success,
            });
        } catch (error) {
            setIsLoading(false);
        }

        setClinicToDeny(null);
    };

    const handleCloseDenyClinicModal = () => {
        setShowDenyClinicConfirmationModal(false);
    };

    const translate = (key: string, subs?: any) => UiHelper.Translate(app, key, subs);

    return (
        <div className={styleGeneral.body}>
            <div className={styleSettings.settings}>
                <div className={styleSettings.heading}>{translate('settings.title')}</div>
                <div className={styleSettings.body}>
                    <div className={styleSettings.section}>
                        <div className={styleSettings.title}>{translate('settings.myClinics')}</div>
                        <p className={styleSettings.description}>{translate('settings.description')}</p>
                        <p className={styleSettings.manageClinics}>
                            <Trans
                                i18nKey="settings.manageClinics"
                                components={{
                                    Link: (
                                        // eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/anchor-has-content
                                        <a
                                            href={SystemHelper.GetRuntimeConfig(
                                                'REACT_APP_PODDER_CENTRAL_CLINICS_LINK'
                                            )}
                                            className={clsx(
                                                styleGeneral.link,
                                                styleGeneral.link_underline,
                                                styleGeneral.link_fontWeightSemiBold
                                            )}
                                            target="_blank"
                                            rel="noreferrer"
                                        />
                                    ),
                                    Sup: <sup />,
                                }}
                            />
                        </p>
                    </div>

                    {app.clinics.length === 0 && (
                        <div className={styleSettings.section}>
                            <p className={styleSettings.noClinics}>{translate('settings.noClinics')}</p>
                        </div>
                    )}

                    {app.clinics.map((clinic) => {
                        return (
                            <div
                                key={uuidv4()}
                                className={styleSettings.section}
                                data-testid={`clinic-${clinic.clinicId}`}
                            >
                                <ClinicDetails
                                    clinicDetails={clinic}
                                    onDontShareDataClick={handleDontShareDataClick}
                                    onShareDataClick={handleShareDataClick}
                                    shouldDisableInvitationButtons={isLoading}
                                    translate={translate}
                                />
                            </div>
                        );
                    })}

                    <Notification data={notification} />
                </div>
            </div>

            <PromptModal
                confirmButtonLabel={translate('settings.dontShareData')}
                confirmButtonColor={ButtonColors.Warning}
                description={translate('settings.dontShareDataModalDescription', {
                    clinicName: clinicToDeny?.clinicName,
                })}
                isOpen={showDenyClinicConfirmationModal}
                onClose={handleCloseDenyClinicModal}
                onConfirmButtonClick={handleConfirmDenyClinicModal}
                title={translate('settings.dontShareDataModalTitle')}
            />
        </div>
    );
}

export default Settings;
