import { IAppState } from '../../app.types';
import ConstantsHelper from '../../../../helpers/ConstantsHelper';
import UtilityHelper from '../../../../helpers/UtilityHelper';
import InsightHelper from '../../../../helpers/InsightHelper';
import UiHelper from '../../../../helpers/UiHelper';
import AnI18NextLibHelper from '../../../../helpers/AnI18NextLibHelper';

const fetchDashboardFulfilled = (state: IAppState, action: any) => {
    const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.fetchDashboard, [
        action.meta?.arg?.beg,
        action.meta?.arg?.end,
    ]);
    if (callKey) {
        delete state.activeHttpCalls[callKey];
    }
    if (action.payload) {
        const insightIds: string[] = [];
        if (action.payload.data.totalCount > 0) {
            /**
             * Scan low then high pattern groups
             */
            InsightHelper.PopulateInsightData(state, action.payload.data.items, insightIds);
        }
        state.currentDashboard = {
            insightIds,
        };
        state.errors[callKey] = UiHelper.CreateErrorMessage({
            level: ConstantsHelper.ErrorLevels.success,
            message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.success'),
            title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.dashboard'),
        });
    }
    state.loadingSemaphore--;
};

export default fetchDashboardFulfilled;
