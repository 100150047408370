import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';

import { sendAzureEvent } from '../helpers/appInsights';
import {
    contributingEventMetaDataActionCreator,
    dashboardMetaDataActionCreator,
    insightIdActionCreator,
} from '../store/app/app.slice';
import { RootState } from '../store/store';
import { appInitialState } from '../store/app/app.initialState';
import SystemHelper from '../helpers/SystemHelper';
import ContributingEventDetail from '../components/contributingEvent/ContributingEventDetail';
import styleGeneral from '../styles/general.module.scss';
import BtnBack from '../components/common/btnBack';
import UiHelper from '../helpers/UiHelper';
import NavigationHelper from '../helpers/NavigationHelper';
import ConstantsHelper from '../helpers/ConstantsHelper';
import InsightHelper from '../helpers/InsightHelper';

function ContributingEvent(props: any) {
    const dispatch = useDispatch();

    const windowSize = useSelector((state: RootState) => state.ui.windowSize);
    const app = SystemHelper.GetCleanState(
        useSelector((state: RootState) => state.app),
        appInitialState
    );

    const translate = (key: string, subs?: any) => UiHelper.Translate(app, key, subs);
    const {
        dashboardBeg,
        dashboardEnd,
        dashboardHasReferrer,
        insightId,
        contributingEventId,
        contributingEventBeg,
        contributingEventEnd,
    } = props?.match?.params ?? {};
    /* istanbul ignore next: Jest dom doesnt provide actual rendering capability so unable to get scroll values */
    const handleScroll = (event: any) =>
        NavigationHelper.HandleScroll(
            undefined,
            scrollActive1,
            undefined,
            setScrollActive1,
            undefined,
            event?.target?.scrollTop
        );

    useEffect(() => {
        if (
            !(
                app.dashboardBeg === dashboardBeg &&
                app.dashboardEnd === dashboardEnd &&
                app.dashboardHasReferrer === (dashboardHasReferrer === 'true')
            )
        ) {
            dispatch(
                dashboardMetaDataActionCreator({
                    dashboardBeg,
                    dashboardEnd,
                    dashboardHasReferrer: dashboardHasReferrer === 'true',
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dashboardBeg, dashboardEnd, dashboardHasReferrer]);

    useEffect(() => {
        if (app.insightId !== insightId) {
            dispatch(insightIdActionCreator({ insightId }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [insightId]);

    useEffect(() => {
        if (
            !(
                app.contributingEventId === contributingEventId &&
                app.contributingEventBeg === contributingEventBeg &&
                app.contributingEventEnd === contributingEventEnd
            )
        ) {
            dispatch(
                contributingEventMetaDataActionCreator({
                    contributingEventId,
                    contributingEventBeg,
                    contributingEventEnd,
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contributingEventId, contributingEventBeg, contributingEventEnd]);

    const dashboard = app.currentDashboard;
    const insight = app.cachedInsights[InsightHelper.ConvertInsightIdSingleToChildAndParent(app.insightId).child];
    const insightEvent = insight?.windowEgvEvent?.find((e) => e.eventId === app.contributingEventId);
    const dataItemReadings = app.currentReadingCombo;
    const [scrollActive1, setScrollActive1] = useState(false);

    useEffect(() => {
        SystemHelper.AppInsightsEvent(app, 'ContributingEvent()', 'Mounted');
        if (insightEvent?.eventId && !app.currentReadingCombo?.fetched) {
            sendAzureEvent(ConstantsHelper.analyticsEventNames.VIEW_EVENT_DETAILS, {
                eventId: app.contributingEventId,
            });
            UiHelper.FetchInsightEventReadings(dispatch, insightId, insightEvent?.eventId, app);
            UiHelper.FetchInsightEventBoluses(dispatch, insightId, insightEvent?.eventId, app);
            UiHelper.FetchInsightEventModes(dispatch, insightId, insightEvent?.eventId, app);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [app, insightEvent, insightId, app.patient?.confidentialityAgreementAccepted, app.patient?.eulaAccepted]);

    useEffect(() => {
        if (
            app.patient.receivedData &&
            !(
                app.dashboardEnd &&
                InsightHelper.ConvertInsightIdSingleToChildAndParent(app.insightId).child.length &&
                dashboard?.insightIds
            )
        ) {
            const payload = UiHelper.GetInsightsDateRange(dispatch, app);

            if (payload.beg && payload.end) {
                UiHelper.ClearErrors(dispatch);
                UiHelper.FetchDashboard(dispatch, payload.beg, payload.end, app);
                UiHelper.FetchDashboardInsulinUsage(dispatch, payload.beg, payload.end, app);
                UiHelper.FetchDashboardInsulinUsageTrend(dispatch, payload.beg, payload.end, app);
                UiHelper.FetchDashboardSummary(dispatch, payload.beg, payload.end, app);
                UiHelper.FetchDashboardSummaryGlucoseTrend(dispatch, payload.beg, payload.end, app);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [app.patient.receivedData, app.patient?.confidentialityAgreementAccepted, app.patient?.eulaAccepted]);

    if (insight) {
        if (windowSize.width !== 0 && windowSize.height !== 0) {
            SystemHelper.AppInsightsInfo(
                app,
                'Dashboard',
                'Debug',
                `New size is ${windowSize.width}x${windowSize.height}`
            );
        }
    }

    if (!insight) {
        return null;
    }

    return (
        <div
            className={styleGeneral.body}
            onScroll={
                /* istanbul ignore next: Jest dom doesnt provide actual rendering capability so unable to get scroll values */ (
                    event
                ) => handleScroll(event)
            }
        >
            <div className={styleGeneral.stickToTopBack}>
                <BtnBack
                    url={NavigationHelper.GenUrlInsight(
                        app.dashboardBeg,
                        app.dashboardEnd,
                        app.dashboardHasReferrer,
                        InsightHelper.ConvertInsightIdSingleToChildAndParent(app.insightId).parent
                    )}
                />
                {/* {newSize.w}x{newSize.h} */}
            </div>
            <div className={styleGeneral.insightEntryDetail}>
                <ContributingEventDetail
                    app={app}
                    dispatch={dispatch}
                    translate={translate}
                    scrollActive1={scrollActive1}
                    insight={insight}
                    contributingEvent={insightEvent}
                    dataItemReadings={dataItemReadings}
                />
            </div>
        </div>
    );
}

export default ContributingEvent;
