import { IAppState } from '../../app.types';
import ConstantsHelper from '../../../../helpers/ConstantsHelper';
import UtilityHelper from '../../../../helpers/UtilityHelper';

const restoreStateFromLocalStorageFulfilled = (state: IAppState, action: any) => {
    if (!action.payload.dashboardHasReferrer) {
        state.dashboardHasReferrer = action.payload.dashboardHasReferrer;
        state.dashboardBeg = action.payload.dashboardBeg;
        state.dashboardEnd = action.payload.dashboardEnd;
    }

    state.allLoaded = true;
    state.calendarDate = action.payload.calendarDate;
    state.contributingEventBeg = action.payload.contributingEventBeg;
    state.contributingEventEnd = action.payload.contributingEventEnd;
    state.contributingEventId = action.payload.contributingEventId;
    state.reloadInProgress = false;
    state.idTag = action.payload.idTag;
    state.insightId = action.payload.insightId;

    UtilityHelper.SettingSet(ConstantsHelper.IntersessionKeys.signOutCanProceed, '0'); // Resets sign out flag
};

export default restoreStateFromLocalStorageFulfilled;
