import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'src/store/store';

import { Clinic } from '../../model/clinic';
import PromptModal from '../promptModal/PromptModal';
import MenuHelper from '../../helpers/MenuHelper';

function InvitedToClinicModal() {
    const app = useSelector((state: RootState) => state.app);

    const { t } = useTranslation();

    const [showInvitedToClinicModal, setShowInvitedToClinicModal] = useState(false);

    useEffect(() => {
        const hasInvitedClinics = app.clinics.some(
            (clinic) => clinic.patientClinicAssociationStatus === Clinic.PatientClinicAssociationStatusEnum.Invited
        );

        const isComingFromLogin = !!app.signInUrl;

        setShowInvitedToClinicModal(hasInvitedClinics && isComingFromLogin);
    }, [app.clinics, app.signInUrl]);

    const handleCloseInvitedToClinicModalClick = () => {
        setShowInvitedToClinicModal(false);
    };

    return (
        <PromptModal
            confirmButtonLabel={t('invitedToClinicModal.buttonLabel')}
            description={t('invitedToClinicModal.description')}
            displayCloseButton={false}
            displayIcon={false}
            isOpen={showInvitedToClinicModal}
            onClose={handleCloseInvitedToClinicModalClick}
            onConfirmButtonClick={MenuHelper.redirectToSettings}
            title={t('invitedToClinicModal.title')}
        />
    );
}

export default InvitedToClinicModal;
