import { IAppState } from '../../app.types';
import { Clinic } from '../../../../model/clinic';
import ConstantsHelper from '../../../../helpers/ConstantsHelper';
import UtilityHelper from '../../../../helpers/UtilityHelper';
import UiHelper from '../../../../helpers/UiHelper';
import AnI18NextLibHelper from '../../../../helpers/AnI18NextLibHelper';

const fetchClinicsFulfilled = (state: IAppState, action: any) => {
    const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.fetchClinics);
    const payload: Clinic[] = action.payload;
    if (callKey) {
        delete state.activeHttpCalls[callKey];
    }
    state.clinics = payload;
    state.errors[callKey] = UiHelper.CreateErrorMessage({
        level: ConstantsHelper.ErrorLevels.success,
        message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.success'),
        title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.settings'),
    });
    state.loadingSemaphore--;
};

export default fetchClinicsFulfilled;
