import { PayloadAction } from '@reduxjs/toolkit';

import { IAppState, IError, IPatient, IReadingsInfo } from './app.types';
import { appInitialState } from './app.initialState';

import { IObjectHash } from '../../types';
import ConstantsHelper from '../../helpers/ConstantsHelper';
import UiHelper from '../../helpers/UiHelper';
import UtilityHelper from '../../helpers/UtilityHelper';

const appReducers = {
    signInUrl: {
        reducer: (state: IAppState, action: PayloadAction<{ signInUrl: string }>) => {
            state.signInUrl = action.payload.signInUrl;
        },
        prepare: ({ signInUrl }: { signInUrl: string }) => ({
            payload: { signInUrl },
        }),
    },
    anI18NextLib: {
        reducer: (state: IAppState, action: PayloadAction<{ anI18Nextlib: any }>) => {
            state.anI18Nextlib = action.payload.anI18Nextlib;
        },
        prepare: ({ anI18Nextlib }: { anI18Nextlib: any }) => ({
            payload: {
                anI18Nextlib,
            },
        }),
    },
    endpointUrlPrefix: {
        reducer: (
            state: IAppState,
            action: PayloadAction<{
                endpointPwdUrlPrefix: string;
                hcpAppUrl: string;
                endpointPdfUrlPrefix: string;
            }>
        ) => {
            state.endpointPwdUrlPrefix = action.payload.endpointPwdUrlPrefix;
            state.hcpAppUrl = action.payload.hcpAppUrl;
            state.endpointPdfUrlPrefix = action.payload.endpointPdfUrlPrefix;
        },
        prepare: ({
            endpointPwdUrlPrefix,
            hcpAppUrl,
            endpointPdfUrlPrefix,
        }: {
            endpointPwdUrlPrefix: string;
            hcpAppUrl: string;
            endpointPdfUrlPrefix: string;
        }) => ({
            payload: {
                endpointPwdUrlPrefix,
                hcpAppUrl,
                endpointPdfUrlPrefix,
            },
        }),
    },
    setMessage: {
        reducer: (state: IAppState, action: PayloadAction<{ message: string }>) => {
            state.message = action.payload.message;
        },
        prepare: ({ message }: { message: string }) => ({
            payload: {
                message,
            },
        }),
    },
    setReloadInProgress: {
        reducer: (state: IAppState, action: PayloadAction<{ reloadInProgress: boolean }>) => {
            state.reloadInProgress = action.payload.reloadInProgress;
        },
        prepare: ({ reloadInProgress }: { reloadInProgress: boolean }) => ({
            payload: {
                reloadInProgress,
            },
        }),
    },
    setActiveHttpCalls: {
        reducer: (state: IAppState, action: PayloadAction<{ activeHttpCalls: IObjectHash<boolean> }>) => {
            state.activeHttpCalls = {
                ...state.activeHttpCalls,
                ...action.payload.activeHttpCalls,
            };
        },
        prepare: ({ activeHttpCalls }: { activeHttpCalls: IObjectHash<boolean> }) => ({
            payload: {
                activeHttpCalls,
            },
        }),
    },
    setReadingsInfo: {
        reducer: (state: IAppState, action: PayloadAction<{ readingsInfo: IReadingsInfo }>) => {
            if (
                !UtilityHelper.IsNull(action.payload?.readingsInfo.fillGaps) &&
                state.readingsInfo?.fillGaps !== action.payload?.readingsInfo.fillGaps
            ) {
                state.currentReadingCombo.readingsCombo = action.payload?.readingsInfo.fillGaps
                    ? state.currentReadingCombo.readingsComboNoGaps
                    : state.currentReadingCombo.readingsComboGaps;
            }

            state.readingsInfo = {
                ...state.readingsInfo,
                ...action.payload?.readingsInfo,
            };
        },
        prepare: ({ readingsInfo }: { readingsInfo: IReadingsInfo }) => ({
            payload: {
                readingsInfo,
            },
        }),
    },
    error: {
        reducer: (state: IAppState, action: PayloadAction<{ key: string; error: IError }>) => {
            if (!action.payload.key) {
                state.errors = appInitialState.errors;
            } else if (!action.payload.error) {
                if (action.payload.key === ConstantsHelper.httpCallAndErrorKeys.general) {
                    state.errors[action.payload.key] = appInitialState.errors[action.payload.key];
                } else {
                    delete state.errors[action.payload.key];
                }
            } else {
                const errorFinal = UiHelper.CreateErrorMessage({
                    ...action.payload.error,
                });

                if (isNaN(errorFinal.level)) {
                    errorFinal.level = errorFinal.message
                        ? ConstantsHelper.ErrorLevels.info
                        : ConstantsHelper.ErrorLevels.clear;
                }

                state.errors[action.payload.key] = errorFinal;
            }
        },
        prepare: ({ key, error }: { key: string; error: IError }) => ({
            payload: {
                key,
                error,
            },
        }),
    },
    loading: {
        reducer: (state: IAppState, action: PayloadAction<{ loadingDir: number }>) => {
            state.loadingSemaphore = Math.max(0, state.loadingSemaphore + action.payload.loadingDir);
        },
        prepare: ({ loadingDir }: { loadingDir: number }) => ({
            payload: {
                loadingDir,
            },
        }),
    },
    dashboardMetaData: {
        reducer: (
            state: IAppState,
            action: PayloadAction<{
                dashboardBeg: string;
                dashboardEnd: string;
                dashboardHasReferrer?: boolean;
            }>
        ) => {
            state.dashboardBeg = action.payload.dashboardBeg;
            state.dashboardEnd = action.payload.dashboardEnd;
            state.dashboardHasReferrer = action.payload.dashboardHasReferrer;
            state.currentSummary = {};
            state.currentSummaryGlucoseTrend = {};
            state.currentDashboard = {};
            state.currentReadingCombo = {};
            state.cachedInsights = {};
        },
        prepare: ({
            dashboardBeg,
            dashboardEnd,
            dashboardHasReferrer,
        }: {
            dashboardBeg: string;
            dashboardEnd: string;
            dashboardHasReferrer?: boolean;
        }) => ({
            payload: {
                dashboardBeg,
                dashboardEnd,
                dashboardHasReferrer,
            },
        }),
    },
    insightId: {
        reducer: (state: IAppState, action: PayloadAction<{ insightId: string }>) => {
            state.insightId = action.payload.insightId;
        },
        prepare: ({ insightId }: { insightId: string }) => ({
            payload: {
                insightId,
            },
        }),
    },

    allReportsRowCnt: {
        reducer: (state: IAppState, action: PayloadAction<{ allReportsRowCnt: number }>) => {
            state.allReportsRowCnt = action.payload.allReportsRowCnt;
        },
        prepare: ({ allReportsRowCnt }: { allReportsRowCnt: number }) => ({
            payload: {
                allReportsRowCnt,
            },
        }),
    },
    contributingEventMetaData: {
        reducer: (
            state: IAppState,
            action: PayloadAction<{
                contributingEventId: string;
                contributingEventBeg: string;
                contributingEventEnd: string;
            }>
        ) => {
            state.contributingEventId = action.payload.contributingEventId;
            state.contributingEventBeg = action.payload.contributingEventBeg;
            state.contributingEventEnd = action.payload.contributingEventEnd;
            state.currentReadingCombo = {};
        },
        prepare: ({
            contributingEventId,
            contributingEventBeg,
            contributingEventEnd,
        }: {
            contributingEventId: string;
            contributingEventBeg: string;
            contributingEventEnd: string;
        }) => ({
            payload: {
                contributingEventId,
                contributingEventBeg,
                contributingEventEnd,
            },
        }),
    },
    updatePatient: {
        reducer: (state: IAppState, action: PayloadAction<{ patient: Partial<IPatient> }>) => {
            state.patient = {
                ...state.patient,
                ...action.payload.patient,
            };
        },
        prepare: ({ patient }: { patient: Partial<IPatient> }) => ({
            payload: {
                patient,
            },
        }),
    },
    pollProfile: {
        reducer: (state: IAppState, action: PayloadAction<{ active: boolean }>) => {
            state.isPolling = action.payload.active;
        },
        prepare: ({ active }: { active: boolean }) => ({
            payload: {
                active,
            },
        }),
    },
};

export default appReducers;
