import { IAppState } from '../../app.types';
import ConstantsHelper from '../../../../helpers/ConstantsHelper';
import UtilityHelper from '../../../../helpers/UtilityHelper';
import UiHelper from '../../../../helpers/UiHelper';
import AnI18NextLibHelper from '../../../../helpers/AnI18NextLibHelper';

const sendFeedbackFulfilled = (state: IAppState, action: any) => {
    const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.sendFeedback, [
        action.meta?.arg?.insightId,
        action.meta?.arg?.feedbackValue,
        action.meta?.arg?.insightIdParent,
    ]);
    if (callKey) {
        delete state.activeHttpCalls[callKey];
    }
    if (action.payload) {
        [action.payload.insightId, action.payload.insightIdParent].forEach((insightId) => {
            if (insightId) {
                const insight = state.cachedInsights[insightId];
                if (insight) {
                    insight.feedbackValue = action.payload.feedbackValue;
                }
            }
        });
    }
    const message = AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.thankYouFeedback');
    state.message = message;
    state.errors[callKey] = UiHelper.CreateErrorMessage({
        level: ConstantsHelper.ErrorLevels.success,
        message,
        showAlways: false,
        title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.feedback'),
    });
    state.loadingSemaphore--;
};

export default sendFeedbackFulfilled;
