import { Trans, useTranslation } from 'react-i18next';
import React from 'react';
import clsx from 'clsx';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import { ButtonColors } from './PromptModal.types';

import closeIcon from '../../assets/images/closeIcon.svg';
import exclamationPointIcon from '../../assets/images/exclamationPointIcon.svg';
import Modal from '../modal/Modal';
import '../../styles/promptModal.scss';

const bemBlockName = 'prompt-modal';

interface IPromptModalProps {
    className?: string;
    confirmButtonColor?: ButtonColors;
    confirmButtonLabel: string;
    description: string;
    displayCloseButton?: boolean;
    displayIcon?: boolean;
    isOpen: boolean;
    onClose: () => void;
    onConfirmButtonClick: () => void;
    subtitle?: string;
    title: string;
}

function PromptModal({
    className,
    confirmButtonLabel,
    confirmButtonColor,
    description,
    displayCloseButton,
    displayIcon,
    isOpen,
    onClose,
    onConfirmButtonClick,
    subtitle,
    title,
}: IPromptModalProps) {
    const { t } = useTranslation();

    if (!isOpen) {
        return null;
    }

    /**
     * TODO: Update spacing. Currently MUI's spacing is set to 10, but for this UX an 8 pixel spacing is used.
     */
    return (
        <Modal>
            <div className={clsx(`${bemBlockName}`, className)}>
                <img
                    alt={t('modal.closeModalIconAlt')}
                    aria-hidden="true"
                    className={`${bemBlockName}__close`}
                    onClick={onClose}
                    src={closeIcon}
                />

                {displayIcon && (
                    <img
                        alt={t('modal.exclamationPointIconAlt')}
                        className={`${bemBlockName}__iconEclamationPoint`}
                        src={exclamationPointIcon}
                    />
                )}

                <div className={`${bemBlockName}__title`}>{title}</div>

                {subtitle && <div className={`${bemBlockName}__subtitle`}>{subtitle}</div>}

                <div className={`${bemBlockName}__description`}>
                    <Trans components={{ bold: <b />, italic: <i /> }}>{description}</Trans>
                </div>

                <Stack spacing={1.6} className={`${bemBlockName}__buttons`}>
                    <Button
                        color={confirmButtonColor}
                        onClick={onConfirmButtonClick}
                        className={clsx('Button', `${bemBlockName}__button`)}
                        variant="contained"
                    >
                        {confirmButtonLabel}
                    </Button>
                    {displayCloseButton && (
                        <Button
                            className={clsx(`${bemBlockName}__button`, 'Button')}
                            color="primary"
                            onClick={onClose}
                            variant="outlined"
                        >
                            {t('modal.cancel')}
                        </Button>
                    )}
                </Stack>
            </div>
        </Modal>
    );
}

PromptModal.defaultProps = {
    confirmButtonColor: 'primary',
    displayIcon: true,
    displayCloseButton: true,
};

export default PromptModal;
