import React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import PatientClinicAssociationStatusChip from './PatientClinicAssociationStatusChip';

import { Clinic } from '../../model/clinic';
import { ITranslator } from '../../types';
import clinicIcon from '../../assets/images/icon-clinic.svg';
import styleSettings from '../../styles/settings.module.scss';

interface IClinicDetailsProps {
    clinicDetails: Clinic;
    onDontShareDataClick: (clinic: Clinic) => void;
    onShareDataClick: (invitationId: string) => void;
    shouldDisableInvitationButtons: boolean;
    translate: ITranslator;
}

function ClinicDetails({
    clinicDetails,
    shouldDisableInvitationButtons,
    onDontShareDataClick,
    onShareDataClick,
    translate,
}: Readonly<IClinicDetailsProps>) {
    const { invitationId } = clinicDetails;

    const handleDontShareDataClick = () => {
        onDontShareDataClick(clinicDetails);
    };

    const handleShareDataClick = () => {
        onShareDataClick(invitationId);
    };

    /**
     * TODO: Update spacing. Currently MUI's spacing is set to 10, but for this UX an 8 pixel spacing is used.
     */
    return (
        <Stack spacing={0.4}>
            <div className={styleSettings.clinicDetails}>
                <div className={styleSettings.iconContainer}>
                    <img src={clinicIcon} alt={translate('clinic')} />
                </div>

                <Stack spacing={0.8}>
                    <div className={styleSettings.title}>{clinicDetails.clinicName}</div>

                    <div className={styleSettings.description}>{clinicDetails.clinicAddress}</div>

                    <Stack spacing={2.4}>
                        <div>
                            <PatientClinicAssociationStatusChip
                                patientClinicAssociationStatus={clinicDetails.patientClinicAssociationStatus}
                                translate={translate}
                            />
                        </div>

                        {clinicDetails.patientClinicAssociationStatus ===
                            Clinic.PatientClinicAssociationStatusEnum.Invited && (
                            <Stack spacing={1.6}>
                                <Button
                                    className="Button"
                                    disabled={shouldDisableInvitationButtons}
                                    onClick={handleShareDataClick}
                                    variant="contained"
                                >
                                    {translate('settings.shareData')}
                                </Button>

                                <Button
                                    className="Button"
                                    disabled={shouldDisableInvitationButtons}
                                    onClick={handleDontShareDataClick}
                                    variant="outlined"
                                >
                                    {translate('settings.dontShareData')}
                                </Button>
                            </Stack>
                        )}
                    </Stack>
                </Stack>
            </div>
        </Stack>
    );
}

export default ClinicDetails;
